import { Box, MobileStepper } from '@mui/material';
import { useEffect, useState } from 'react';
import Carousel from 'react-spring-3d-carousel';
import Text from './Text';
import Space from './Space';

const ReviewSlideshow = () => {
  const height = 'min(50vw, 300px)';
  const scrollSpeedMs = 3000;
  const reviews = [
    {
      image: 'reviews/person0.png',
      text: 'Jag är så imponerad av Klimra. Fick ersättning helt automatiskt',
      name: 'Michael Malmgren'
    },
    {
      image: 'reviews/person1.png',
      text: 'Klimra tog hand om allt. Fick tillbaka pengar jag inte trodde jag skulle få',
      name: 'Helena Thybell'
    },
    {
      image: 'reviews/person2.png',
      text: 'Bra kundservice. Ringde dem på en lördag och fick svar direkt',
      name: 'Catharina Erlandsson'
    },
    {
      image: 'reviews/person3.png',
      text: 'På tiden att en sådan här tjänst skulle komma. Sjukt smidigt',
      name: 'Jacob Matz'
    },
    {
      image: 'reviews/person4.png',
      text: 'Hela min umgängeskrets använder er. Fem av fem i betyg',
      name: 'Olle Sjögren'
    },
    {
      image: 'reviews/person5.png',
      text: 'Grymt! Kunsumeternas rättigheter ska alltid stå först, inte tågbolagens',
      name: 'Johan Henriksson'
    }
  ];

  const [reviewsToShow, setReviewsToShow] = useState(
    reviews.map((element, index) => {
      return {
        key: index,
        content: {
          ...(
            <Box
              onClick={(e) => {
                setActiveStep(index);
              }}
              sx={{
                width: height,
                flexShrink: 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <img
                src={element.image}
                alt={element.name}
                style={{
                  width: `calc(${height} - 40px)`,
                  height: `calc(${height} - 40px)`,
                  objectFit: 'contain',
                  borderRadius: '50%',
                  overflow: 'hidden'
                }}></img>
              <Box
                sx={{
                  textAlign: 'center',
                  marginTop: '10px',
                  backgroundColor: 'rgba(255,255,255,0.9)',
                  padding: '5px',
                  borderRadius: '5px'
                }}>
                <Text text={`"${element.text}".`} type="h5.300" sx={{ fontFamily: 'Kanit' }} />
                <Space type="large" />
                <Text text={element.name} type="h7.300" sx={{ fontFamily: 'Poppins' }} />
              </Box>
            </Box>
          )
        }
      };
    })
  );
  const [activeStep, setActiveStep] = useState<number>(0);
  return (
    <div style={{ height: '500px', margin: '50px', width: 'min(100vw, 1000px)' }}>
      <Carousel
        slides={reviewsToShow}
        goToSlide={activeStep}
        offsetRadius={5}
        showNavigation={false}
      />
    </div>
  );
};

export default ReviewSlideshow;
