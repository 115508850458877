import toast from 'react-hot-toast';
import { Profile, RegisterUser } from '../interfaces';
import { isSessionValid } from './AuthHelper';
import { acceptedTrainCompanies } from './Constants';

export const apiUrl = !window.location.href.includes('localhost')
  ? 'https://api.klimra.com'
  : 'http://localhost:8080';

const fetchAuthSession = async () => {
  const idToken = localStorage.getItem('idToken');
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  if (!idToken || !accessToken || !refreshToken) {
    return null;
  }
  return { idToken, accessToken, refreshToken };
};

const getJWT = async () => {
  const session = await fetchAuthSession();
  if (!session) {
    return null;
  }
  return session.idToken;
};

export const refreshTokens = async () => {
  //const session = await fetchAuthSession();
  const provider = localStorage.getItem('provider');
  // if (!session) {
  //   return { error: 'No session found' };
  // }
  if (!provider) {
    return { error: 'No provider found' };
  }
  try {
    const resp = await fetch(apiUrl + '/auth/refresh/' + provider, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      // body: JSON.stringify({ refreshToken: session.refreshToken })
    });
    const data = await resp.json();
    if (!resp.ok) {
      // Handle errors based on response data
      return { error: data.error || 'Failed to refresh tokens' };
    }
    if (data.error) {
      return { error: data.error };
    }

    if (!data.accessToken || !data.idToken) {
      return { error: 'No tokens found' };
    }
    // localStorage.setItem('accessToken', data.accessToken);
    // localStorage.setItem('idToken', data.idToken);
    return data;
  } catch (error) {
    return { error: error };
  }
};
const authedCall = async (url: string, method: string, body?: any) => {
  try {
    // let jwt = await getJWT();
    //
    // if (!jwt) {
    //   throw new Error('No JWT found');
    // }
    const valid = await isSessionValid();
    if (!valid) {
      toast.success('Refreshing tokens');

      const refreshed = await refreshTokens();

      if (refreshed.error) {
        if (refreshed.error === 'Couldnt validate user') {
          console.log('couldnt validate user');

          //clearTokens();

          return { error: refreshed.error, authenticated: false };
        }
        return { error: refreshed.error, authenticated: true };
      }

      // Re-fetch JWT after refresh
      // jwt = await getJWT();
      // if (!jwt) {
      //   throw new Error('Failed to obtain JWT after refresh');
      // }
    }

    const response = await fetch(apiUrl + url, {
      method: method,
      headers: {
        // Authorization: `Bearer ${jwt}`,
        'Content-Type': 'application/json'
      },
      body: body ? JSON.stringify(body) : null,
      credentials: 'include'
    });

    const data = await response.json();
    console.log('authed request data', data);

    if (data['error'] === 'user not registered') {
      return { error: 'user not registered', authenticated: true };
    }
    if (data['error'] === 'Couldnt validate user') {
      return { error: 'couldnt validate user', authenticated: false };
    }

    if (!response.ok) {
      throw new Error(data.error || 'An unknown error occurred');
    }

    return data;
  } catch (error: any) {
    toast.error(`An error has occurred: ${error.message}`);
    return { error: error.message, authenticated: true };
  }
};

const clearTokens = () => {
  console.log('Clearing tokens');

  localStorage.removeItem('accessToken');
  localStorage.removeItem('idToken');
  localStorage.removeItem('refreshToken');
};

export const exchangeCode = async (code: string, provider: string, redirectURI: string) => {
  try {
    const resp = await fetch(apiUrl + '/auth/exchangeCode/google', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify({ code, provider, redirectURI })
    });
    if (resp === undefined) {
      return { error: 'No response from server' };
    }
    return await resp.json();
  } catch (error) {
    return { error: error };
  }
};

export const getMyEmails = async () => {
  try {
    const resp = await authedCall('/email/me', 'GET');
    if (resp.error) {
      return resp;
    }
    console.log('data:', resp);
    return resp;
  } catch (error) {
    return null;
  }
};
export const getMyTickets = async () => {
  try {
    const resp = await authedCall('/ticket/me', 'GET');
    if (resp.error) {
      return resp;
    }
    console.log('data:', resp);
    return resp;
  } catch (error) {
    return null;
  }
};

export const acceptTicket = async (ticketId: string) => {
  try {
    const resp = await authedCall('/ticket/me/activateTicket/' + ticketId, 'POST');
    if (resp.error) {
      return resp;
    }
    return resp;
  } catch (error) {
    return { error: error };
  }
};

export const deleteMyProfile = async () => {
  try {
    const resp = await authedCall('/user/me', 'DELETE');
    if (resp.error) {
      return resp;
    }
    console.log('data:', resp);
    return resp;
  } catch (error) {
    return null;
  }
};

export const getMyProfile = async () => {
  try {
    const resp = await authedCall('/user/me', 'GET');
    if (resp.error === 'user not registered') {
      console.log('User need to register1');

      return { error: 'user not registered' };
    }
    if (resp.error) {
      return resp;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const saveMyProfile = async (profile: Profile) => {
  try {
    const resp = await authedCall('/user/me', 'PUT', profile);
    if (resp.error) {
      return resp;
    }
    return resp;
  } catch (error) {
    return null;
  }
};

export const checkUsername = async (username: string) => {
  try {
    const resp = await fetch(apiUrl + '/user/validUsername/' + username);
    const data = await resp.json();
    return data;
  } catch (error) {
    return null;
  }
};

export const apiSignUp = async (user: RegisterUser, blobs: Blob[]) => {
  try {
    // Convert the user object to a JSON string
    const userData = JSON.stringify(user);

    // Convert each file blob to a Base64-encoded string
    const filePromises = blobs.map(async (file, index) => {
      const stepName = acceptedTrainCompanies[index].replace(/\s+/g, '_'); // Replace spaces with underscores
      const reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onloadend = () => {
          const base64String = reader.result as string;
          resolve({ name: `${stepName}.pdf`, data: base64String });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    const files = await Promise.all(filePromises);

    // Create a JSON object with user data and file data
    const payload = {
      userData,
      files
    };

    const data = await authedCall('/user/me', 'POST', payload);
    // console.log('data:', data);
    if (data.error) {
      throw Error(data.error);
    }
    toast.success('Du är registrerad!');

    return true;
  } catch (error: any) {
    toast.error('Error signing up: ' + error);
    return false;
  }
};

export const linkEmail = async (code: string, provider: string, redirectURI: string) => {
  try {
    const resp = await authedCall('/email/me', 'POST', { code, provider, redirectURI });
    if (resp.error) {
      return resp;
    }
    return resp;
  } catch (error) {
    return null;
  }
};
export const unlinkEmail = async (email: string) => {
  try {
    const resp = await authedCall('/email/me/' + email, 'delete');
    if (resp.error) {
      return resp;
    }
    return resp;
  } catch (error) {
    return null;
  }
};
