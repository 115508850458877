import { Box } from '@mui/material';
import PrettyButton from '../PrettyButton';
import ReviewSlideshow from '../common/ReviewSlideshow';
import styles from '../../styles';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Model } from '../../Model';
import { observer } from 'mobx-react-lite';
import { useInView } from 'react-intersection-observer';
import Text from '../common/Text';
import Space from '../common/Space';
import HomeTutorialBox from './HomeTutorialBox';

interface Props {
  model: Model;
}

const Home = observer((props: Props) => {
  const model = props.model;

  return (
    <>
      <Box sx={{ textAlign: 'center' }}>
        <Space type="xxlarge" />
        <Text text="Automatisk kompensation." type="h1.400" />
        <Space type="large" />
        <Text
          text="Trött på förseningar och krånglet med att kräva ersättning? Du är inte ensam."
          type="h4.400"
          sx={{ padding: '0px 5vw' }}
        />
        <Space type="xlarge" />
        <Link to={'/login'}>
          <PrettyButton text="Logga in" />
        </Link>
        <Space type="small" />
        <p style={{ margin: '0px' }}>eller</p>
        <Space type="small" />

        <Link to={'/signup'}>
          <PrettyButton text="Skapa konto" />
        </Link>
        <Space type="small" />

        <div ref={null} style={{ textAlign: 'left', height: 'calc(22vw + 25px)' }}>
          <img
            src="/imgs/train.png"
            alt="Train"
            style={{
              position: 'relative',
              //transform: `translateX(${scrollPosition * 0.5}px)`,
              //transition: 'transform 0.1s',
              left: '-0vw',
              maxHeight: '100%'
            }}
          />
        </div>
        <Space type="medium" />

        <Text text="HUR DET FUNGERAR" type="h6.300" sx={{ fontFamily: 'Inter' }} />
        <Space type="small" />
        <Text text="Steg för steg" type="h2.400" />
        <Space type="large" />
        <Space type="medium" />

        <Box className="rowToScrollRow" sx={{ gap: '30px', padding: '20px' }}>
          <HomeTutorialBox
            hasArrow={true}
            image="/imgs/onboarding_1.png"
            title="1. Köp tågbiljetter som vanligt"
            text="Fortsätt att köpa tågbiljetter där du brukar. Med ett konto hos Klimra kan du vidarebefordra tågbiljetter via mail så att vi automatiskt kan spåra redan gjorda, samt framtida resor."
          />
          <HomeTutorialBox
            hasArrow={true}
            image="/imgs/onboarding_2.svg"
            title="2. Res och luta dig tillbaka"
            text="Om vi upptäcker att något av dina tåg har anlänt för sent, driver vi kompensationsärendent åt dig direkt, utan att du behöver göra något."
          />
          <HomeTutorialBox
            hasArrow={false}
            image="/imgs/onboarding_3.svg"
            title="3. Få pengar in på ditt konto"
            text="När ärendet är klart får du dina pengarna insatta på ditt konto. Vanligtvis tar det ca 1-2 veckor från dess att förseningen har skett."
          />
        </Box>
        <Space type="xxlarge" />
        <Box
          className="rowToColumn"
          sx={{
            width: '100vw',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: '#ECF0F1',
            padding: '10px 0px 10px 10px'
          }}>
          <Box
            sx={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Box
              sx={{
                maxWidth: '800px',
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column'
              }}>
              <Text text="Ta det lugnt, vi löser din kompensation" type="h3.400" />
              <Space type="large" />
              <Text
                text="Slipp att fylla i krångliga onlineformulär, ringa tågbolagen och hålla reda på resedetaljer från veckor tillbaka."
                type={'h5.300'}
                sx={{ fontFamily: 'Poppins' }}
              />
              <Space type="large" />
              <Space type="medium" />

              <Box sx={{ display: 'inline' }}>
                <Link to={'/signup'}>
                  <PrettyButton text="Skapa konto" />
                </Link>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              maxHeight: '100%',
              height: 'calc(300px)',
              objectFit: 'contain',
              alignSelf: 'flex-end',
              alignItems: 'right'
            }}>
            <img
              style={{
                maxHeight: '100%',
                objectFit: 'scale-down',
                padding: '10px 0px'
              }}
              src="/imgs/computer.png"></img>
          </Box>
        </Box>
        <Space type="xxlarge" />
        <Text text="LITA PÅ VÅRA KUNDER, INTE PÅ OSS" type="h6.300" sx={{ fontFamily: 'Inter' }} />
        <Space type="small" />
        <Text text="Recensioner" type="h2.400" />
        <Space type="medium" />
        <Box sx={{ display: 'flex', justifyContent: 'center', userSelect: 'none' }}>
          <ReviewSlideshow />
        </Box>
      </Box>
    </>
  );
});

export default Home;
