import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import CustomModal from '../CustomModal';
import PrettyButton from '../PrettyButton';
import Space from '../common/Space';
import Text from '../common/Text';

interface Props {
  email: string;
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const UnlinkEmailConfirmation = (props: Props) => {
  const { open, onClose, onDelete } = props;
  return (
    <CustomModal title="Ta bort mejladress?" isOpen={open} onClose={() => onClose()}>
      <Text
        text="Om du fortsätter så kommer vi inte längre kunna driva ärenden för att ge dig kompensation."
        type="h7.500"
        sx={{ color: '#636364' }}
      />
      <Space type="large" />
      <Box sx={{ display: 'flex', gap: '20px' }}>
        <PrettyButton
          text="Ja, jag är säker"
          onClick={() => {
            onDelete();
          }}
        />
        <PrettyButton
          backgroundColor="transparent"
          color="black"
          text="Nej"
          onClick={() => onClose()}
          sx={{ minWidth: 'none' }}
        />
      </Box>
    </CustomModal>
  );
};

export default UnlinkEmailConfirmation;
