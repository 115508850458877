import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Text from './common/Text';

interface Props {
  to: string;
  text?: string;
  children?: any;
}

const NavButton = (props: Props) => {
  const location = useLocation();

  const active =
    location.pathname === '/my-pages/cases'
      ? location.pathname === props.to
      : location.pathname.includes(props.to);

  const selectedColor = active ? '#555' : 'black';

  return (
    <Box
      sx={{
        display: 'flex'
      }}>
      <Link to={props.to} style={{ textDecoration: 'none', color: selectedColor }}>
        {props.children ? (
          <div>{props.children}</div>
        ) : (
          <center>
            <Text
              text={props.text || ''}
              type="h6.400"
              sx={{ fontFamily: 'Inter', marginBottom: '5px' }}
            />
            {active && (
              <div
                style={{ height: '2px', backgroundColor: selectedColor, borderRadius: '10px' }}
              />
            )}
          </center>
        )}
        {/* <Button sx={{ display: 'block', fontSize: '1.5rem' }}>Logga in</Button> */}
      </Link>
    </Box>
  );
};
export default NavButton;
