import React from 'react';
import Text from '../../common/Text';
import styles from '../../../styles';

const ProfileFooterMessage = () => {
  return (
    <div>
      <Text
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          color: styles.gray3,
          opacity: '0.5'
        }}
        suffix={
          <a
            href="mailto:contact@klimra.com"
            style={{ fontWeight: 800, cursor: 'pointer', color: styles.gray3 }}>
            kontakta oss
          </a>
        }
        type="h7.400"
        text="Här kommer dina förseningsärenden att dyka upp. Vanligtvis tar det ett par minuter från dess att du har vidarebefordrat din biljett tills att biljetten syns här. Skulle din resa inte dyka upp kan du "
      />
      <Text
        sx={{
          textAlign: 'center',
          fontFamily: 'Inter',
          color: styles.gray3,
          opacity: '0.5'
        }}
        suffix={
          <a
            href="mailto:biljett@klimra.com"
            style={{ fontWeight: 800, cursor: 'pointer', color: styles.gray3 }}>
            biljett@klimra.com
          </a>
        }
        type="h7.400"
        text="Har du glömt att vidarebefordra dina biljetter? Gör det till "
      />
    </div>
  );
};

export default ProfileFooterMessage;