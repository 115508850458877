import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PrettyButton from './PrettyButton';
import Text from './common/Text';
import Space from './common/Space';
interface Props {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  buttonText?: string;
  title: string;
}
const style = {
  position: 'absolute',
  display: 'flex',
  userSelect: 'none',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(80vw, 500px)',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: '32px'
};

const CustomModal = (props: Props) => {
  const { children, isOpen, onClose, buttonText, title } = props;
  return (
    <Modal
      sx={{ userSelect: 'none' }}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={isOpen}>
        <Box>
          <Box sx={style}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <img style={{ cursor: 'pointer' }} onClick={onClose} src="/icons/close_x.svg" />
            </Box>
            <Text text={title} type="h4.600" sx={{ fontFamily: 'Poppins' }} />
            <Space type="medium" />
            {children}
            {buttonText && (
              <Box sx={{ textAlign: 'center', marginTop: '20px' }}>
                <PrettyButton text={buttonText} onClick={onClose} />
              </Box>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CustomModal;
