import { RegisterUser } from '../interfaces';
import { Model } from '../Model';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { jwtDecode } from 'jwt-decode';
import { urlConstructor } from './Tools';
import { apiUrl } from './API';

const googleAuthClientId =
  '509260630295-7r1o8d0jfqagr1d0l5m73deinkf0dvgo.apps.googleusercontent.com';

export const isSessionValid = async () => {
  // const idToken = localStorage.getItem('idToken');
  //parse the payload of the accessToken and check if it is expired
  // if (!idToken) return false;
  try {
    console.log(apiUrl)
    //Moved over to use cookies instead
    const response = await fetch(apiUrl + '/auth/validateSession', {
      method: 'GET',
      credentials: 'include', // Ensure cookies are sent with the request
    });
    const data = await response.json();
    if (response.ok) {
      return data.valid;
    } else {
      console.error('Error validating session:', data);
      return false;
    }
    // const decodedToken = jwtDecode(idToken);
    // if (!decodedToken) return false;
    // console.log('decodedToken: ', decodedToken);
    //
    // const currentTime = Date.now() / 1000;
    // const exp = decodedToken.exp ?? 0;
    // console.log('exp: ', exp);
    // console.log('currentTime: ', currentTime);
    //
    // const x = exp > currentTime;
    // return x;
  } catch (error) {
    console.error('Error decoding token:', error);
    return true;
  }
};

export const openGoogleAuth = async () => {
  window.open(
    urlConstructor('https://accounts.google.com/o/oauth2/auth', {
      response_type: 'code',
      client_id: googleAuthClientId,
      scope: 'openid email profile',
      redirect_uri: window.location.origin + '/oauthLogin/callback/google',
      access_type: 'offline',
      prompt: 'consent'
    }),
    '_self'
  );
};
