import { Typography } from '@mui/material';
import { Model } from '../../Model';
import CustomModal from '../CustomModal';
import Text from '../common/Text';
import styles from '../../styles';
import Space from '../common/Space';
import PrettyButton from '../PrettyButton';

interface Props {
  model: Model;
  open: boolean;
  onClose: () => void;
}

const EmailLinkingInfoPopup = (props: Props) => {
  return (
    <CustomModal isOpen={props.open} onClose={props.onClose} title="Vidarebefordra biljett">
      <Text
        text="Varför behöver du vidarebefordra dina biljetter?"
        sx={{ color: styles.boldGray }}
        type="h7.600"
      />
      <Space type="medium" />
      <Text
        text="För att kunna hantera ditt förseningsärende behöver vi veta vilket tåg du befann dig på. Vidarebefordra biljetterna till "
        suffix={
          <span>
            <b>biljett@klimra.com</b>
          </span>
        }
        sx={{ color: styles.boldGray }}
        type="h7.400"
      />
      <Space type="medium" />
      <Text
        text="Därefter kommer du kunna se och hantera dina förseningsärenden på sidan “Mina ärenden”."
        sx={{ color: styles.boldGray }}
        type="h7.400"
      />

      <Space type="large" />

      <PrettyButton
        text="Jag förstår"
        onClick={props.onClose}
        width="min(300px, 90%)"
        sx={{ alignSelf: 'center' }}
      />
      {/*<Text
        text="Varför behöver vi tillgång till din mejl?"
        sx={{ color: styles.boldGray }}
        type="h7.600"
      />
      <Space type="medium" />
      <Text
        text="För att kunna erbjuda en smidig och automatiserad tjänst för reseersättningar behöver du auktorisera dig via din mejl operatör och ge oss tillgång till din mejl inkorg. Det gör att vi snabbt kan identifiera och hantera eventuella ersättningskrav."
        sx={{ color: styles.boldGray }}
        type="h7.400"
      />
      <Space type="medium" />
      <Text
        text="Notera att Klimra absolut inte läser all din e-post - våra algoritmer letar strikt efter tågbiljetter."
        sx={{ color: styles.boldGray }}
        type="h7.400"
      />
      <Space type="large" />
      <PrettyButton
        text="Jag förstår"
        onClick={props.onClose}
        width="min(300px, 90%)"
        sx={{ alignSelf: 'center' }}
      />*/}
    </CustomModal>
  );
};

export default EmailLinkingInfoPopup;
