import styles from '../../styles';
import PrettyButton from '../PrettyButton';

interface Props {
  onClick: () => void;
  text: string;
}
const GoogleButton = (props: Props) => {
  const { onClick, text } = props;
  return (
    <PrettyButton
      onClick={() => {
        onClick();
      }}
      text={text}
      backgroundColor="transparent"
      iconName="/icons/google.svg"
      width="min(300px, 100%)"
      textSx={{
        color: styles.gray,
        fontFamily: 'Poppins',
        fontSize: '14px',
        fontWeight: '400'
      }}
    />
  );
};

export default GoogleButton;
