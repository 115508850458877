import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { FAQs } from '../../utils/Constants';
import Text from '../common/Text';
import Space from '../common/Space';
const FAQ = () => {
  return (
    <Box sx={{ padding: '0px min(100px, 10vw) 50px', textAlign: 'center' }}>
      <Space type="large" />
      <Text text="Vanliga frågor" type="pageHeader.600" />
      <Space type="medium" />
      <Text
        text="Här kan du hitta svar till de vanligaste frågorna vi får om Klimra."
        type="h7.300"
      />
      <Space type="large" />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
        {FAQs.map((faq, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: '40px',
              overflow: 'hidden',
              maxWidth: '900px',
              textAlign: 'left'
            }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header">
                <Text text={faq.header} type="h7.500" />
              </AccordionSummary>
              <AccordionDetails>
                {faq.content.map((content, index) => (
                  <>
                    <Text text={content} type="h7.300" />
                    <Space type="medium" />
                  </>
                ))}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
      <Box sx={{ height: '50px' }}></Box>
    </Box>
  );
};
export default FAQ;
