import { observer } from 'mobx-react-lite';
import { Model } from '../../Model';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Loader from '../Loader';
import { Box, Divider } from '@mui/material';
import ProfilePageOption from './ProfilePageOption';
import DetailsPage from './DetailsPage';
import EmailLinks from './EmailLinks';
import CasesPage from './CasesPage';
import CustomModal from '../CustomModal';
import PrettyButton from '../PrettyButton';
import styles from '../../styles';
import Text from '../common/Text';
import Space from '../common/Space';
import PrettyCard from '../common/PrettyCard';

interface Props {
  model: Model;
}

const MyPages = observer((props: Props) => {
  const { model } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);

  useEffect(() => {
    if (!model.isAuthed) {
      navigate('/login');
      return;
    }
  }, [model.isAuthed]);
  if (!model.profile) return <Loader />;

  if (location.pathname.includes('cases')) return <CasesPage model={model} />;
  else if (location.pathname === '/') return <CasesPage model={model} />;
  else if (location.pathname.includes('emails')) return <EmailLinks model={model} />;
  else if (location.pathname.includes('details')) return <DetailsPage model={model} />;

  return (
    <Box className="rowToColumn" sx={{ width: '100vw', gap: '20px', margin: '0px auto' }}>
      <CustomModal
        title="Logga ut"
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}>
        <Text text="Är du säker att du vill logga ut?" type="h7.500" sx={{ color: '#636364' }} />
        <Space type="large" />
        <Box sx={{ display: 'flex', gap: '20px' }}>
          <PrettyButton
            text="Ja, jag är säker"
            onClick={() => {
              model.signOut();
              setShowLogoutConfirmation(false);
            }}
          />
          <PrettyButton
            backgroundColor="transparent"
            color="black"
            text="Nej"
            onClick={() => setShowLogoutConfirmation(false)}
            sx={{ minWidth: 'none' }}
          />
        </Box>
      </CustomModal>
      <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <PrettyCard>
          <div>
            <Text text="Inställningar" type="pageHeader.700" />
            <Space type="medium" />

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Text
                text="Din utbetalda kompensation"
                type="h7.400"
                sx={{ color: styles.gray3, opacity: '0.5' }}
              />
              <Box sx={{ flex: 1 }} />
              <PrettyButton
                iconName="/icons/money.svg"
                text={model.profile.total_revenue.toString() + ' SEK'}
              />
            </Box>
          </div>

          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.70)',
              borderRadius: '20px',
              padding: '0px 20px'
            }}>
            <ProfilePageOption
              icon="/icons/mail.svg"
              title="Mejl"
              description="Lägg till eller byt mejladress"
              onClick={() => navigate('/my-pages/emails')}
            />
            <Divider />
            <ProfilePageOption
              icon="person"
              title="Konto"
              description="Ändra ditt konto"
              onClick={() => navigate('/my-pages/details')}
            />
            <Divider />

            <ProfilePageOption
              icon="/icons/logout.svg"
              title="Logga ut"
              description="Logga ut från ditt konto"
              onClick={() => setShowLogoutConfirmation(true)}
            />
          </Box>
          <center>
            <Text
              text="Har du frågor? Tveka inte på att höra av dig."
              type="h7.400"
              sx={{ color: styles.gray2 }}
            />
          </center>
        </PrettyCard>
      </Box>
    </Box>
  );
});

export default MyPages;
