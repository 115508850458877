import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PrettyButton from '../PrettyButton';
import Text from '../common/Text';
import Space from '../common/Space';
import styles from '../../styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onAccept: () => void;
}
const style = {
  position: 'absolute',
  display: 'flex',
  userSelect: 'none',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'min(80vw, 500px)',
  bgcolor: 'background.paper',
  borderRadius: 4,
  boxShadow: 24,
  p: '32px'
};

const AccountCongratsPopup = (props: Props) => {
  const { open, onClose, onAccept } = props;
  return (
    <Modal
      sx={{ userSelect: 'none' }}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={open}>
        <Box>
          <Box sx={style}>
            <Box sx={{ position: 'absolute', top: '10px', right: '10px' }}>
              <img style={{ maxHeight: '90px' }} src="/icons/congratsStars.svg" />
            </Box>
            <Text text="Grattis" type="h4.600" sx={{ fontFamily: 'Poppins' }} />
            <Space type="large" />
            <Text
              text="Du har nu skapat ett konto hos Klimra!"
              type="h7.600"
              sx={{ color: styles.boldGray }}
            />
            <Space type="large" />
            <Text
              text="På sidan “Mina ärenden” kan du övervaka dina kompensationsärenden."
              type="h7.600"
              sx={{ color: styles.boldGray }}
            />
            <Space type="large" />
            <Box sx={{ textAlign: 'center' }}>
              <PrettyButton
                text="Gå till Mina ärenden"
                onClick={onAccept}
                sx={{ width: 'min(80%, 300px)', padding: '10px 0px' }}
              />
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default AccountCongratsPopup;
