import { Box, Checkbox, Divider } from '@mui/material';
import { Model } from '../../Model';
import styles from '../../styles';
import { MainTicket, StatusEnum } from '../../interfaces';
import { parseTime, prettyDate, timeDifferenceInMinutes } from '../../utils/Tools';
import { act, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import TrainSchedule from './TrainSchedule';
import PrettyButton from '../PrettyButton';
import CustomModal from '../CustomModal';
import { observer } from 'mobx-react-lite';
import Text from '../common/Text';
import Space from '../common/Space';

interface Props {
  model: Model;
  ticket: MainTicket;
}
const TicketBox = observer((props: Props) => {
  const { model, ticket } = props;

  const [expanded, setExpanded] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const subTickets = ticket.sub_tickets
    .slice()
    .sort((a, b) => parseTime(a.expected_departure_time) - parseTime(b.expected_departure_time));

  if (subTickets.length === 0) return <>No sub tickets</>;
  const firstTicket = subTickets[0];
  const lastTicket = subTickets[subTickets.length - 1];
  const firstDeparture = firstTicket.departure_station;
  const lastArrival = lastTicket.arrival_station;

  const date = ticket.validity_date;

  const expectedDeparture = firstTicket.expected_departure_time;
  const expectedArrival = lastTicket.expected_arrival_time;
  const actualArrival = lastTicket.actual_arrival_time ?? lastTicket.expected_arrival_time;
  const actualDeparture = firstTicket.actual_departure_time ?? firstTicket.expected_departure_time;
  const uniqueCompanies = new Set(subTickets.map((subTicket) => subTicket.train_company));

  let statusColor = '';
  let statusText = '';
  let statusCode = -1;
  if (!ticket.status) return <>No status</>;

  if (ticket.is_valid_case === false) {
    (statusColor = '#BC2B2B'), (statusText = 'ej berättigad');
    statusCode = 1;
  } else if (ticket.status === StatusEnum.COMPLETE) {
    statusCode = 2;

    statusColor = '#6CA390';
    statusText = 'ärendet lyckades';
  } else if (ticket.status === StatusEnum.WAITING) {
    statusCode = 3;

    statusColor = '#BC2B2B';
    statusText = 'godkännande krävs';
  } else {
    statusCode = 4;
    statusColor = '#F3F314';
    statusText = 'ärendet är inskickat';
  }

  const delayMinutes = timeDifferenceInMinutes(expectedArrival, actualArrival);

  return (
    <Box
      sx={{
        backgroundColor: styles.casesBox,
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        display: 'flex',
        padding: 'min(3vw, 20px)',
        borderRadius: 'calc(1vw)',
        flexDirection: 'column',
        userSelect: 'none'
      }}>
      <CustomModal title="Läs mer" isOpen={showMoreInfo} onClose={() => setShowMoreInfo(false)}>
        <Text
          text="Ge oss klartecken att påbörja ditt ärende."
          type="h7.600"
          sx={{ color: styles.boldGray }}
        />
        <Space type="medium" />
        <Text
          text="
          Vi har identifierat en försening i din resehistorik som du är berättigad kompensation för.
          Eftersom resan skedde innan du hade ett konto på Klimra, vill vi försäkra oss om att du
          inte redan har fått kompensation för detta ärende."
          type="h7.400"
          sx={{ color: styles.boldGray }}
        />
        <Space type="medium" />
        <Text
          text="
          Notera att ifall du klickar i rutan trots att du redan fått kompensation för resan, så
          kommer vi kontakta dig och eventuellt stänga ner ditt konto."
          type="h7.400"
          sx={{ color: styles.boldGray }}
        />
        <Space type="large" />

        <PrettyButton text="Jag förstår" onClick={() => setShowMoreInfo(false)} />
      </CustomModal>
      <Box
        onClick={() => setExpanded((prev) => !prev)}
        sx={{
          display: 'flex',
          position: 'relative',
          borderRadius: '20px',
          cursor: 'pointer',
          gap: '20px'
        }}>
        <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
          <Box sx={{ display: 'inline-flex', flexDirection: 'column' }}>
            <Text
              text={
                date
                  ? new Date(date).toLocaleDateString('sv-SE', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    })
                  : 'n'
              }
              type={'h6.700'}
              sx={{ color: date ? '#3B3B3B' : 'transparent' }}
            />
            {delayMinutes > 0 && (
              <Box sx={{ display: 'flex', gap: '6px' }}>
                <Text
                  text={delayMinutes + ' min'}
                  type={'h8.800'}
                  sx={{ color: styles.casesGray }}
                />
                <Text text={'försenad'} type={'h8.400'} sx={{ color: styles.casesGray }} />
              </Box>
            )}

            <Text
              text={Array.from(uniqueCompanies).join(', ')}
              type={'h8.400'}
              sx={{ color: styles.casesGray }}
            />
            <Text
              text={`${ticket.departure_station} - ${ticket.arrival_station}`}
              type={'h8.400'}
              sx={{ color: styles.casesGray }}
            />

            {/*<h5 style={{ margin: '0px' }}>{ticket.price} kr</h5>*/}
          </Box>
        </Box>
        <Box className="hideOnMobile" sx={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ flex: '1' }}></Box>
          <TrainSchedule
            departureLocation={ticket.departure_station}
            arrivalLocation={ticket.arrival_station}
            departureTime={expectedDeparture}
            arrivalTime={expectedArrival}
            actualArrivalTime={actualArrival}
            actualDepartureTime={actualDeparture}
          />
        </Box>
        <Box>
          <Box
            className="TB_posAbs_mobile"
            sx={{
              backgroundColor: 'white',
              display: 'flex',
              borderRadius: '20px',
              padding: 'min(1.5vw, 15px)',
              alignItems: 'center',

              gap: '1vw',
              right: '0px',
              top: '0px'
            }}>
            <Box
              sx={{
                width: 'min(1vw + 10px)',
                height: 'min(1vw + 10px)',
                borderRadius: '50%',
                backgroundColor: statusColor
              }}
            />
            <Box sx={{ flex: 1, display: 'flex', gap: '6px' }}>
              <Text type="h8.700" text="Status: " />
              <Text type="h8.400" text={statusText} />
            </Box>
          </Box>
        </Box>
      </Box>
      {expanded && (
        <Box>
          <Divider sx={{ margin: '20px' }} />
          {statusCode === 1 && (
            <Box>
              <Text text="Mer info" type="h6.700" sx={{ color: styles.darkGray }} />
              <Space type="small" />
              <Text
                text="Eftersom din resa inte var tillräckligt försenad så är du inte berättigad
                kompensation."
                type="h7.400"
                sx={{ color: styles.casesGray }}
              />
            </Box>
          )}

          {statusCode === 2 && (
            <Box>
              <Text text="Mer info" type="h6.700" sx={{ color: styles.darkGray }} />
              <Space type="small" />
              <Text
                text="Din kompensationsansökan till SJ lyckades. pengarna har satts in på ditt konto."
                type="h7.400"
                sx={{ color: styles.casesGray }}
              />
            </Box>
          )}

          {statusCode === 3 && (
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Checkbox
                  value={{ isAccepted }}
                  onChange={(e) => {
                    setIsAccepted(!isAccepted);
                  }}
                />
              </Box>
              <Box>
                <Text
                  text="Jag har inte själv krävt kompensation för detta ärende tidigare"
                  type="h8.400"
                  sx={{ color: styles.casesGray }}
                />
                <Text
                  text="Läs mer"
                  type="h8.500"
                  sx={{
                    color: styles.casesGray,
                    textDecorationLine: 'underline',
                    cursor: 'pointer'
                  }}
                  onClick={() => setShowMoreInfo(true)}
                />
              </Box>
              <Box sx={{ flex: 1 }} />
              <Box>
                <PrettyButton
                  text="Skicka ansökan"
                  disabled={isAccepted === false}
                  onClick={() => model.acceptTicket(ticket)}
                />
              </Box>
            </Box>
          )}

          {statusCode === 4 && (
            <Box>
              <Text text="Mer info" type="h6.700" sx={{ color: styles.darkGray }} />
              <Space type="small" />
              <Text
                text="Vi har skickat in kompensationsansökan till SJ Vanligtvis tar det 1-2 veckor innan
                pengarna sätts in på ditt konto."
                type="h7.400"
                sx={{ color: styles.casesGray }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
});

export default TicketBox;
