import { Box, Fade, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from '../../styles';

const OnLoad = () => {
  const fadeInTime = 1000;
  const [show, setShow] = useState<boolean>(true);
  const [transition, setTransition] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTransition(true);
      setTimeout(() => {
        setShow(false);
      }, fadeInTime);
    }, fadeInTime);
  }, []);

  const icon = (
    <div
      style={{
        display: show ? 'flex' : 'none',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: styles.headerColor,
        zIndex: 9999
      }}>
      <img
        className="pulse"
        style={{ width: '200px', height: '200px' }}
        src="/logo_small.svg"></img>
    </div>
  );
  return (
    <Fade timeout={fadeInTime} appear={false} in={!transition}>
      {icon}
    </Fade>
  );
};

export default OnLoad;
