import { Box, Typography } from '@mui/material';
import ReviewSlideshow from '../common/ReviewSlideshow';
import PrettyButton from '../PrettyButton';
import Spacer from '../common/Spacer';
import { Link } from 'react-router-dom';
import Text from '../common/Text';
import Space from '../common/Space';

const AboutUs = () => {
  return (
    <>
      <Box className="rowToColumn" sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ flex: '0.2' }} />
        <Box sx={{ flex: '1', display: 'inline-block' }}>
          <Text text="Mer än bara teknik" type="pageHeader.600" />
          <Text
            text="Trött på förseningar och krånglet med att kräva ersättning? Du är inte ensam."
            type="h7.300"
          />
          <Space type="medium" />

          <Text text="I Europa har nästan 20 % av alla tåg varit försenade i år!" type="h7.300" />
          <Space type="medium" />

          <Text
            text="Resenärer tvingas fylla i krångliga onlineformulär, ringa tågbolag och hålla reda på
            resedetaljer från veckor tillbaka, allt för att få den kompensation de har rätt till."
            type="h7.300"
          />
          <Space type="medium" />

          <Text
            text=" Men det behöver inte vara så komplicerat. Vi har utvecklat en mjukvara som helt
            automatiserar processen att kräva kompensation. Vår avancerade teknik säkerställer att
            du får den ersättningen du förtjänar utan huvudvärk."
            type="h7.300"
          />
          <Space type="large" />

          <PrettyButton
            text="Kontakta oss"
            onClick={() => {
              window.open('mailto:contact@klimra.com', '_self');
            }}
            sx={{ width: 'min(300px, 90%)', padding: '15px 0px' }}
          />
        </Box>
        <Box sx={{ flex: '0.7' }} />

        <Box
          sx={{
            width: 'min(80vw, 500px)',
            display: 'flex',
            justifyContent: 'center'
          }}>
          <ReviewSlideshow />
        </Box>
        <Box sx={{ flex: '0.2' }} />
      </Box>
    </>
  );
};

export default AboutUs;
