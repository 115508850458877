import './Text.css';

interface Props {
  text: string;
  type: string;
  suffix?: any;
  sx?: any;
  onClick?: () => void;
}

const Text = (props: Props) => {
  const { text, sx, type, onClick, suffix } = props;
  const x = type.split('.');
  return (
    <h6 className={x[0]} style={{ margin: '0px', ...sx, fontWeight: x[1] }} onClick={onClick}>
      {text}
      <span>{suffix}</span>
    </h6>
  );
};

export default Text;
