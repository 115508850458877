import { MainTicket } from '../interfaces';

export const fakeTickets = [
  {
    id: '762c457b-60f2-4cf9-b36b-9733df2c516a',
    first_name: 'Pontus',
    last_name: 'Thybell',
    validity_date: '',
    departure_station: 'Sthlm Central',
    arrival_station: 'Uppsala C',
    booking_num: '',
    is_resplus: true,
    thread_id: '190914096fcfadcb',
    message_id: '190914096fcfadcb',
    attachment_id:
      'ANGjdJ-NOtzY27_Dc_uQhIZvQ3CxCdAimcfZGZzCPO-BVzouh8s0GjDYJ3ToUxpTQ53aRyjybuMhNqylY1vAGBtq5Xx_uoK1IlnqAe-ROSW6NtMKMxQtaYBRVcx5pdjFIEliYI2TNHtsQFxrhKrhunnVoeLQc4hEkig15yiiDuxQBsZYRnBpmJWR-ZMSqKwag0fjjXavUzUhUsCO7eQK67bTTjFBGEyFKTVa-OwqPLJWo0hv47FKk6gV4fXVWcSX-1Pm53362oXS6n-vAu6tKRAi9hP5qqDDMgiDQYccEUi1M7q8mj1wBqmfkNFoBIltjqf-6EVKbC9FoOnI3jNZc6xhmWA5DVX6ZgphuCng2MaVZZ3EXkwrR1qHBRlluWGGsiVthZ2oMYrPNJovZ-3e',
    price: '11300',
    is_valid_case: null,
    status: 'waiting',
    user_id: 'c460b2c4-77cd-4258-bc47-a8b15c13ebc3',
    email_id: '115133839349705200397',
    created_at: '2024-07-20T16:27:11.540Z',
    updated_at: '2024-07-20T16:27:11.540Z',
    user: {
      id: 'c460b2c4-77cd-4258-bc47-a8b15c13ebc3',
      sub: '115133839349705200397',
      username: null,
      first_name: 'Pontus',
      last_name: 'Thybell',
      social_security_number: '200308123736',
      bank_number: null,
      phone_number: '767728071',
      total_revenue: 0,
      total_debt: 0,
      total_paid: 0,
      created_at: '2024-07-20T12:06:18.513Z',
      updated_at: '2024-07-20T12:06:18.513Z'
    },
    sub_tickets: [
      {
        id: 'bd835981-f580-4c76-a590-e0614b557ed0',
        train_num: '92800',
        ticket_num: 'HDR7501U0001',
        train_company: 'Mälartåg',
        expected_departure_time: '11:11:00',
        expected_arrival_time: '11:49:00',
        actual_departure_time: null,
        actual_arrival_time: null,
        departure_station: 'Sthlm Central',
        arrival_station: 'Uppsala C',
        main_ticket_id: '762c457b-60f2-4cf9-b36b-9733df2c516a',
        train_id: null,
        created_at: '2024-07-20T16:27:11.561Z',
        updated_at: '2024-07-20T16:27:11.561Z',
        train: null
      }
    ]
  },
  {
    id: 'd5b7e79c-c17e-4d4c-b992-7a2600453e8d',
    first_name: 'Catarina',
    last_name: 'Erlandsson',
    validity_date: '',
    departure_station: 'Sthlm Central',
    arrival_station: 'Alingsås stn',
    booking_num: '',
    is_resplus: false,
    thread_id: '1907d0cdd815ceb7',
    message_id: '1907d0cdd815ceb7',
    attachment_id:
      'ANGjdJ-8H_dY2_TFubt7ZCXoacL8y0ivbutFIhmg15o_fu5jySR9QRu0chxDG4l7oId9VxuAsqX0FbBN5A64_08K3aXc4Uxqy2FB6BhZYkVl38L_UHFU0FzoBKgNeJoHSRV6mSsllOusbVjP0aFTtqfQP-jdJ8jzSL-Nrazl_YMlcPJJ-ZDsFxpS7NuCtnsLGOHwsf67VPMmjbZQQ8QORDSSEspXp592uOHHzc1DXRr80W51iPvkbxwYwjIBNst7fj7C3NWjNnTc4NKzNIeBgGiYS060E58sOMyiaKPTM1TC-o4FpxVOVwC2YPOSwFnvP_w3Fk5-tOCG8Dm-7rwMuUygzpFOniucnlj6jyZ-m6r-vfSwpsfVMrBfTcXpP4zoUW0bTH2nXa6FMlWWQGhw',
    price: null,
    is_valid_case: null,
    status: 'active',
    user_id: 'c460b2c4-77cd-4258-bc47-a8b15c13ebc3',
    email_id: '115133839349705200397',
    created_at: '2024-07-20T16:27:11.644Z',
    updated_at: '2024-07-20T16:27:11.644Z',
    user: {
      id: 'c460b2c4-77cd-4258-bc47-a8b15c13ebc3',
      sub: '115133839349705200397',
      username: null,
      first_name: 'Pontus',
      last_name: 'Thybell',
      social_security_number: '200308123736',
      bank_number: null,
      phone_number: '767728071',
      total_revenue: 0,
      total_debt: 0,
      total_paid: 0,
      created_at: '2024-07-20T12:06:18.513Z',
      updated_at: '2024-07-20T12:06:18.513Z'
    },
    sub_tickets: [
      {
        id: '4a6dbccc-5005-4d3b-acb2-a07cea81d6a7',
        train_num: '43300',
        ticket_num: 'JYY4980E0001',
        train_company: 'SJ',
        expected_departure_time: '12:14:00',
        expected_arrival_time: '15:02:00',
        actual_departure_time: null,
        actual_arrival_time: null,
        departure_station: 'Sthlm Central',
        arrival_station: 'Alingsås stn',
        main_ticket_id: 'd5b7e79c-c17e-4d4c-b992-7a2600453e8d',
        train_id: null,
        created_at: '2024-07-20T16:27:11.665Z',
        updated_at: '2024-07-20T16:27:11.665Z',
        train: null
      },
      {
        id: '27009df3-0248-4ae8-9b4b-159bcbc89990',
        train_num: '6043300',
        ticket_num: 'JYY4980E0001',
        train_company: 'SJ',
        expected_departure_time: '15:15:00',
        expected_arrival_time: '15:55:00',
        actual_departure_time: null,
        actual_arrival_time: null,
        departure_station: 'Alingsås stn',
        arrival_station: 'Göteborg C',
        main_ticket_id: 'd5b7e79c-c17e-4d4c-b992-7a2600453e8d',
        train_id: null,
        created_at: '2024-07-20T16:27:11.684Z',
        updated_at: '2024-07-20T16:27:11.684Z',
        train: null
      }
    ]
  },
  {
    id: '762c457b-60f2-4cf9-b36b-9733df2c516b',
    first_name: 'Pontus',
    last_name: 'Thybell',
    validity_date: '',
    departure_station: 'Sthlm Central',
    arrival_station: 'Uppsala C',
    booking_num: '',
    is_resplus: true,
    thread_id: '190914096fcfadcb',
    message_id: '190914096fcfadcb',
    attachment_id:
      'ANGjdJ-NOtzY27_Dc_uQhIZvQ3CxCdAimcfZGZzCPO-BVzouh8s0GjDYJ3ToUxpTQ53aRyjybuMhNqylY1vAGBtq5Xx_uoK1IlnqAe-ROSW6NtMKMxQtaYBRVcx5pdjFIEliYI2TNHtsQFxrhKrhunnVoeLQc4hEkig15yiiDuxQBsZYRnBpmJWR-ZMSqKwag0fjjXavUzUhUsCO7eQK67bTTjFBGEyFKTVa-OwqPLJWo0hv47FKk6gV4fXVWcSX-1Pm53362oXS6n-vAu6tKRAi9hP5qqDDMgiDQYccEUi1M7q8mj1wBqmfkNFoBIltjqf-6EVKbC9FoOnI3jNZc6xhmWA5DVX6ZgphuCng2MaVZZ3EXkwrR1qHBRlluWGGsiVthZ2oMYrPNJovZ-3e',
    price: '11300',
    is_valid_case: null,
    status: 'active',
    user_id: 'c460b2c4-77cd-4258-bc47-a8b15c13ebc3',
    email_id: '115133839349705200397',
    created_at: '2024-07-20T16:27:11.540Z',
    updated_at: '2024-07-20T16:27:11.540Z',
    user: {
      id: 'c460b2c4-77cd-4258-bc47-a8b15c13ebc3',
      sub: '115133839349705200397',
      username: null,
      first_name: 'Pontus',
      last_name: 'Thybell',
      social_security_number: '200308123736',
      bank_number: null,
      phone_number: '767728071',
      total_revenue: 0,
      total_debt: 0,
      total_paid: 0,
      created_at: '2024-07-20T12:06:18.513Z',
      updated_at: '2024-07-20T12:06:18.513Z'
    },
    sub_tickets: [
      {
        id: 'bd835981-f580-4c76-a590-e0614b557ed0',
        train_num: '92800',
        ticket_num: 'HDR7501U0001',
        train_company: 'Mälartåg',
        expected_departure_time: '11:11:00',
        expected_arrival_time: '11:49:00',
        actual_departure_time: null,
        actual_arrival_time: null,
        departure_station: 'Sthlm Central',
        arrival_station: 'Uppsala C',
        main_ticket_id: '762c457b-60f2-4cf9-b36b-9733df2c516a',
        train_id: null,
        created_at: '2024-07-20T16:27:11.561Z',
        updated_at: '2024-07-20T16:27:11.561Z',
        train: null
      }
    ]
  }
] as any as MainTicket[];
