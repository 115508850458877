import { Box } from '@mui/material';
import styles from '../styles';
import { Link, useNavigate } from 'react-router-dom';
import Space from './common/Space';
import Text from './common/Text';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <footer style={{ backgroundColor: styles.headerColor, padding: '60px 5vw' }}>
        <Box className="rowToColumn" sx={{ gap: '20px', color: 'black' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'flex-start',
              maxWidth: '500px'
            }}>
            <img
              src="/logo.svg"
              style={{
                width: '100%',
                maxHeight: '100%',
                objectFit: 'contain'
              }}
            />
            <Space type="large" />
            <Text
              text="Vår avancerade teknik säkerställer att du får den ersättningen du förtjänar utan huvudvärk."
              type="h7.500"
              sx={{ lineHeight: '191%' }}
            />
          </Box>
          <Box
            className="centerToLeft"
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flex: 1
            }}>
            <Box
              sx={{ display: 'flex', width: 'fit-content', flexDirection: 'column', gap: '20px' }}>
              <Text text="Nå oss" type="h6.600" />
              <a href="tel:+46761993236">
                <div style={{ margin: '0px', cursor: 'pointer', display: 'flex', gap: '20px' }}>
                  <img src="/icons/phone.svg" />
                  <Text text="+46 761993236" type="h7.400" />
                </div>
              </a>

              <a href="mailto:contact@klimra.com">
                <div style={{ margin: '0px', cursor: 'pointer', display: 'flex', gap: '20px' }}>
                  <img src="/icons/email_small.svg" />
                  <Text text="contact@klimra.com" type="h7.400" />
                </div>
              </a>

              <a href="https://maps.app.goo.gl/arMpSG94iix9i4zJ7" target="_blank">
                <div style={{ margin: '0px', cursor: 'pointer', display: 'flex', gap: '20px' }}>
                  <img src="/icons/location.svg"></img>
                  <Text text="Östermalmsgatan 26A, Stockholm" type="h7.400" />
                </div>
              </a>
            </Box>
          </Box>
          <Box
            className="centerToLeft"
            sx={{
              display: 'flex',
              flex: 0.7
            }}>
            <Box
              sx={{ display: 'flex', width: 'fit-content', flexDirection: 'column', gap: '10px' }}>
              <Text text="Företag" type="h6.600" />
              <div
                style={{ margin: '0px', cursor: 'pointer', display: 'flex' }}
                onClick={() => {
                  navigate('/about-us');
                }}>
                <Text text="Om oss" type="h7.400" />
              </div>
              <span
                style={{ margin: '0px', cursor: 'pointer' }}
                onClick={() => {
                  window.open('mailto:contact@klimra.com', '_self');
                }}>
                <Text text="Kontakta oss" type="h7.400" />
              </span>
              <Text text="Legalt" type="h6.600" />
              <span
                style={{ margin: '0px', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/privacy-policy');
                }}>
                <Text text="Integritetspolicy" type="h7.400" />
              </span>
              <span
                style={{ margin: '0px', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/user-terms');
                }}>
                <Text text="Användarvillkor" type="h7.400" />
              </span>
              <span
                style={{ margin: '0px', cursor: 'pointer' }}
                onClick={() => {
                  navigate('/cookies');
                }}>
                <Text text="Cookies" type="h7.400" />
              </span>
            </Box>
            <Box
              className="showOnMobile"
              sx={{
                display: 'flex',
                gap: '7px',
                flex: 1,
                justifyContent: 'right',
                alignItems: 'flex-end',
                alignSelf: 'flex-end'
              }}>
              <Text
                text="Med stöd av:"
                type="h7.500"
                sx={{ fontFamily: 'Inter', fontSize: '10px' }}
              />
              <img
                src="/imgs/SSE.png"
                style={{
                  maxWidth: '85px',
                  maxHeight: '100%',
                  objectFit: 'scale-down',
                  marginBottom: '2px'
                }}
              />
            </Box>
          </Box>

          <Box
            className="hideOnMobile"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '15px',
              flex: 0.6,
              justifyContent: 'center'
            }}>
            <img
              src="/imgs/SSE.png"
              style={{ maxWidth: '200px', maxHeight: '100%', objectFit: 'contain' }}
            />
          </Box>
        </Box>
      </footer>
      <Box sx={{ backgroundColor: '#334647', display: 'flex', padding: '40px 5vw' }}>
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <a
            target="_blank"
            style={{ display: 'flex', justifyContent: 'center' }}
            href="https://www.instagram.com/klimra.se/">
            <img src="/icons/instagram.svg" />
          </a>
          <a
            target="_blank"
            style={{ display: 'flex', justifyContent: 'center' }}
            href="https://www.facebook.com/profile.php?id=61561592940008">
            <img src="/icons/facebook.svg" />
          </a>
          <a
            target="_blank"
            style={{ display: 'flex', justifyContent: 'center' }}
            href="https://www.linkedin.com/company/klimra/">
            <img src="/icons/linkedin.svg" />
          </a>
          <a
            target="_blank"
            style={{ display: 'flex', justifyContent: 'center' }}
            href="https://www.tiktok.com/@klimra.com">
            <img src="/icons/tiktok.svg" />
          </a>
        </Box>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Text
            text="Org. nr. 559490-0986"
            type="h7.500"
            sx={{
              color: styles.lightGray,
              margin: '0px',
              fontFamily: 'Inter',
              fontWeight: 500
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
