import { Backdrop, Box, Button, Fade, MobileStepper, Modal, Typography } from '@mui/material';
import { Model } from '../../Model';
import CustomModal from '../CustomModal';
import Space from '../common/Space';
import PrettyButton from '../PrettyButton';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink
} from '@react-pdf/renderer';
import { KeyboardArrowLeft, KeyboardArrowRight, WidthFull } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { acceptedTrainCompanies } from '../../utils/Constants';

const style = {
  position: 'absolute',
  display: 'flex',
  userSelect: 'none',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '95dvh',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: '16px'
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F0F0F0',
    padding: 30
  },
  section: {
    margin: 10,
    padding: 10
  },
  text: {
    fontSize: 12,
    marginBottom: 20
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 20
  },
  signature: {
    marginTop: 50,
    fontSize: 12,
    textAlign: 'left'
  },
  signatureLine: {
    marginTop: 30,
    marginBottom: 10
  }
});

export const DynamicProxyPDF = (props: { company: string; SSN: string; name: string }) => {
  const { company, name, SSN } = props;
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={{ textAlign: 'center' }}>
            <Text style={{ ...styles.title, fontSize: '18px' }}>Fullmakt</Text>
          </View>
          <Text style={styles.text}>
            Härmed befullmäktigas Klimra AB, org.nr. 559490-0986, eller den som Klimra AB sätter i
            sitt ställe att avseende krav i enlighet med lag (2015:953) om kollektivtrafikresenärers
            rättigheter samt enligt EU:s förordning 2021/782 om rättigheter och skyldigheter för
            tågresenärer mellan undertecknad och [{company}]:
          </Text>
          <Text style={styles.text}>
            * anhängiggöra och utföra talan inför domstolar, myndigheter och inrättningar samt
            skiljemän;
          </Text>
          <Text style={styles.text}>
            * mottaga delgivningar och andra meddelanden, dock icke stämningar;
          </Text>
          <Text style={styles.text}>* mottaga och kvittera medel;</Text>
          <Text style={styles.text}>
            * föra kontakter, föra förhandlingar och träffa avtal; samt
          </Text>
          <Text style={styles.text}>
            * i alla övriga avseenden företräda undertecknads intressen mot [{company}] eller den
            saken eljest kan anses röra i ovanstående krav.
          </Text>
          <Text style={styles.text}>Ort: Stockholm</Text>
          <Text style={styles.text}>Datum: {new Date().toLocaleDateString()}</Text>
          <Text style={styles.signature}>_____________________________________________</Text>
          <Text style={styles.text}>
            [{name}], pers.nr. [{SSN}]
          </Text>
        </View>
      </Page>
    </Document>
  );
};

interface Props {
  model: Model;
  open: boolean;
  onClose: () => void;
  SSN: string;
  name: string;
}
const ProxyInfoPopup = (props: Props) => {
  const { model, open, onClose, SSN, name } = props;
  const isOpen = open;
  const steps = acceptedTrainCompanies;

  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <Modal
      sx={{ userSelect: 'none' }}
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}>
      <Fade in={isOpen}>
        <Box sx={style}>
          {/* Navigation arrows on both sides of the viewer */}
          <PDFViewer showToolbar style={{ flex: 1, height: '100%' }}>
            <DynamicProxyPDF company={steps[activeStep]} SSN={SSN} name={name} />
          </PDFViewer>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                Nästa
                {<KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {<KeyboardArrowLeft />}
                Tillbaka
              </Button>
            }
          />
          <Space type="medium" />
          <PDFDownloadLink
            document={<DynamicProxyPDF company={steps[activeStep]} SSN={SSN} name={name} />}
            fileName="proxy_info.pdf"
            style={{ textDecoration: 'none', alignSelf: 'center' }}>
            {({ blob, url, loading, error }) => {
              return loading ? 'Laddar...' : <PrettyButton text="Ladda ner" />;
            }}
          </PDFDownloadLink>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ProxyInfoPopup;
