import { observer } from 'mobx-react-lite';
import { Outlet, Link, useLocation } from 'react-router-dom';
import { Model } from '../Model';
import Header from './Header';
import { Box, Divider } from '@mui/material';
import Footer from './Footer';
import { useEffect } from 'react';
interface Props {
  model: Model;
}
const Layout = observer((props: Props) => {
  const { model } = props;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const dontUseLayout = location.pathname === '/' && !model.profile;
  const exceptionPaths = ['/cookies', '/privacy', '/forgot-password', '/reset-password'];

  if (dontUseLayout)
    return (
      <Box sx={{ overflowX: 'hidden' }}>
        <Header model={model} />
        <Outlet />
        <Footer />
      </Box>
    );
  return (
    <Box sx={{ minHeight: '100svh' }}>
      <Header model={model} />
      <Box
        sx={{
          minHeight: 'calc(100svh - 64px)',
          overflowX: 'hidden',
          padding: '20px min(20px, 2vw)',
          display: 'flex',
          justifyContent: 'center',
          position: 'relative'
        }}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
});

export default Layout;
