import { Box, Button, Typography } from '@mui/material';
import styles from '../styles';
import Text from './common/Text';

interface Props {
  text: string;
  iconName?: string;
  backgroundColor?: string;
  color?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  width?: string;
  fontWeight?: number;
  sx?: any;
  textSx?: any;
  className?: string; //For the className prop of Tailwindcss
}
const PrettyButton = (props: Props) => {
  const { iconName, text, disabled, backgroundColor, color } = props;
  const bgC = backgroundColor || styles.mainbuttonColor;
  const tC = color || 'white';
  return (
    <Button
      onClick={props.onClick}
      type={props.type}
      disabled={props.disabled}
      className={props.className}
      sx={{
        textTransform: 'none',
        minWidth: 'min(145px, 30vw)',
        display: 'inline-flex',
        textAlign: 'center',
        cursor: 'pointer',
        userSelect: 'all',
        backgroundColor: bgC,
        opacity: disabled === true ? '0.3' : '1', //decreased opacity since it didn't look clear enough when disabled /Nima
        border: bgC === 'transparent' ? '1px solid black' : 'none',
        boxShadow: bgC !== 'transparent' ? '0px 2.765px 2.765px rgba(0, 0, 0, 0.25)' : 'none',
        maxHeight: '50px',
        gap: '5px',
        borderRadius: '11px',
        padding: 'min(6px, 1.5vw)',
        textDecoration: 'none',
        width: props.width || 'auto',
        ...props.sx,

        ':hover': {
          backgroundColor: bgC,
          opacity: '0.95'
        }
      }}>
      {!!iconName && (
        <img
          style={{ display: 'inline', maxHeight: '2rem', marginRight: '5px', padding: '5px' }}
          src={iconName}
        />
      )}
      <Text
        text={text}
        sx={{
          alignSelf: 'center',
          textDecoration: 'none',
          textDecorationLine: 'none',
          color: tC,
          userSelect: 'none',
          margin: !iconName ? '0px' : '0px auto 0px 0px',
          justifyContent: 'center',
          fontWeight: props.fontWeight || 500,
          ...props.textSx
        }}
        type={'h7.500'}
      />
    </Button>
  );
};

export default PrettyButton;
