import { observer } from 'mobx-react-lite';
import { Model } from '../../Model';
import { Box, TextField } from '@mui/material';
import BackArrow from '../common/BackArrow';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../Loader';
import PrettyButton from '../PrettyButton';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';
import styles from '../../styles';
import Text from '../common/Text';
import Space from '../common/Space';
import PrettyCard from '../common/PrettyCard';

interface Props {
  model: Model;
}

const DetailsPage = observer((props: Props) => {
  const { model } = props;

  const navigate = useNavigate();

  const [profileState, setProfileState] = useState(model.profile!);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const onChange = (attributeName: string, value: string) => {
    setProfileState({ ...profileState, [attributeName]: value });
  };

  const onDeleteAccount = () => {
    console.log('deleting account');
    model.deleteMyProfile();
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    console.log('submitting');
    await model.saveMyProfile(profileState);
  };

  if (!model.profile) return <Loader />;
  return (
    <Box
      sx={{
        display: 'inline-flex',
        width: '100vw',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap'
      }}>
      <DeleteAccountConfirmation
        open={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        onDelete={() => {
          setShowDeleteConfirmation(false);
          onDeleteAccount();
        }}
      />
      <PrettyCard>
        <BackArrow
          onClick={() => {
            navigate('/my-pages');
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: 'min(90%, 350px)',
            alignSelf: 'center'
          }}>
          <Text text="Ändra profil" type="pageHeader.700" />
          <Text
            text="Uppdatera dina uppgifter"
            type="h7.400"
            sx={{ opacity: 0.5, fontFamily: 'Inter' }}
          />
          <Space type="large" />
          <Box
            component="form"
            onSubmit={onSubmit}
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            <TextField
              placeholder="Förnamn"
              label="Förnamn"
              value={profileState['first_name']}
              onChange={(e) => onChange('first_name', e.target.value)}
            />
            <Space type="medium" />
            <TextField
              placeholder="Efternamn"
              label="Efternamn"
              value={profileState['last_name']}
              onChange={(e) => onChange('last_name', e.target.value)}
            />
            <Space type="medium" />
            <TextField
              placeholder="Personnummer"
              label="Personnummer"
              value={profileState['social_security_number']}
              onChange={(e) => onChange('social_security_number', e.target.value)}
            />
            <Space type="medium" />
            <TextField
              placeholder="Telefonnummer"
              label="Telefonnummer"
              value={profileState['phone_number']}
              onChange={(e) => onChange('phone_number', e.target.value)}
            />
            <Space type="large" />
            <Space type="medium" />

            <PrettyButton type="submit" text="Spara ändringar" />
            <Space type="medium" />

            <Box sx={{ display: 'flex', gap: '6px' }}>
              <Text text="Hur raderar jag mitt konto?" type="h7.400" sx={{ color: styles.gray2 }} />
              <Text
                text="Läs mer här."
                type="h7.600"
                sx={{ color: styles.gray2, cursor: 'pointer', textDecorationLine: 'underline' }}
                onClick={() => setShowDeleteConfirmation(true)}
              />
            </Box>
          </Box>
        </Box>
      </PrettyCard>
    </Box>
  );
});

export default DetailsPage;
