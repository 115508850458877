import React from 'react';
import Space from '../../common/Space';
import { Box } from '@mui/material';
import Text from '../../common/Text';
import styles from '../../../styles';

const NoTicketsMessage = () => {
  return (
    <div>
      <Space type="xlarge" />
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {<Text
          sx={{
            textAlign: 'center',
            fontFamily: 'Inter',
            color: styles.gray3,
            opacity: '0.5'
          }}
          suffix={
            <a
              href="mailto:contact@klimra.com"
              style={{ fontWeight: 800, cursor: 'pointer', color: styles.gray3 }}>
              kontakta oss
            </a>
          }
          type="h7.400"
          text="Ooops, här var det tomt. Antingen så åker du för lite tåg eller så har vi missat din resa. I så fall, "
        />}
      </Box>{' '}
    </div>
  );
};

export default NoTicketsMessage;