import { observer } from 'mobx-react-lite';
import { Model } from '../Model';
import { Box, Button, Divider, TextField } from '@mui/material';
import styles from '../styles';
import { useEffect, useState } from 'react';
import PrettyButton from './PrettyButton';
import { openGoogleAuth } from '../utils/AuthHelper';
import { Link, useNavigate } from 'react-router-dom';
import GoogleButton from './common/GoogleButton';
import PrettyCard from './common/PrettyCard';
interface Props {
  model: Model;
}
const Login = observer((props: Props) => {
  const { model } = props;

  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const authed = await model.isSessionValid();

      if (model.profile) {
        navigate('/my-pages/cases');
        return;
      }
      if (authed) {
        navigate('/register');
      }
    }
    checkAuth(); // Server now handles sessionValid check --> Async is needed
  }, [model.isAuthed, model.profile]);
  const isLogin = window.location.pathname === '/login';

  const texts = {
    header: isLogin ? 'Välkommen!' : 'Skapa ett konto',
    subheader: isLogin
      ? 'Logga in på ditt konto för att se dina ärenden'
      : 'Helt gratis. Säg upp kontot när du vill.'
  };

  /*
        <Button
          onClick={() => {
            openGoogleAuth();
          }}>
          Login
        </Button> */

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex',
        justifyContent: 'center',
        width: '100vw',
        alignItems: 'center',
        height: 'auto'
      }}>
      <PrettyCard
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          borderRadius: '20px',
          justifyContent: 'center',
          alignContent: 'center',
          textAlign: 'center',
          width: 'min(80vw, 500px)',
          padding: '60px',
          height: 'fit-content'
        }}>
        <h2>{texts.header}</h2>
        <h4>{texts.subheader}</h4>
        <center>
          <GoogleButton
            onClick={() => {
              console.log('aiwnda', openGoogleAuth());
            }}
            text={isLogin ? 'Logga in via Google' : 'Skapa ett konto via Google'}
          />
        </center>
        <h5 style={{ color: styles.gray }}>
          {isLogin ? (
            <>
              Har du inget konto?{' '}
              <Link to="/signup">
                <span
                  style={{
                    fontWeight: '700',
                    textDecorationLine: 'underline',
                    color: styles.gray
                  }}>
                  Registera dig!
                </span>
              </Link>
            </>
          ) : (
            <>
              Har du redan ett konto?{' '}
              <Link to="/login">
                <span
                  style={{
                    fontWeight: '700',
                    textDecorationLine: 'underline',
                    color: styles.gray
                  }}>
                  Logga in!
                </span>
              </Link>
            </>
          )}
        </h5>
      </PrettyCard>
    </Box>
  );
});

export default Login;
