import { observer } from 'mobx-react-lite';
import { Model } from '../../Model';
import { Box, InputAdornment, TextField } from '@mui/material';
import BackArrow from '../common/BackArrow';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Loader from '../Loader';
import PrettyButton from '../PrettyButton';
import { AccountCircle } from '@mui/icons-material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteAccountConfirmation from './DeleteAccountConfirmation';
import UnlinkEmailConfirmation from './UnlinkEmailConfirmation';
import SSOButton from '../common/SSOButton';
import {
  getEmailProviderFromPath,
  googleClientId,
  googleScopes,
  urlConstructor
} from '../../utils/Tools';
import PrettyCard from '../common/PrettyCard';
import Text from '../common/Text';
import Space from '../common/Space';
import styles from '../../styles';
import GoogleButton from '../common/GoogleButton';

interface Props {
  model: Model;
}

const EmailLinks = observer((props: Props) => {
  const { model } = props;

  const [deleteEmail, setDeleteEmail] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setDeleteEmail('');
    if (model.emails) return;

    model.loadMyEmails();
  }, [model.emails]);

  const unlinkEmail = async () => {
    console.log('unlinking email', deleteEmail);
    model.unlinkEmail(deleteEmail);
  };

  if (!model.profile || !model.emails) return <Loader />;

  const handleGoogleAuthClick = async () => {
    window.open(
      urlConstructor('https://accounts.google.com/o/oauth2/auth', {
        response_type: 'code',
        client_id: googleClientId,
        scope: googleScopes,
        redirect_uri: window.location.origin + '/oauth/callback/google',
        access_type: 'offline',
        prompt: 'consent'
      }),
      '_self'
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100vw',
        justifyContent: 'center',
        alignContent: 'center',
        flexWrap: 'wrap'
      }}>
      <UnlinkEmailConfirmation
        email={deleteEmail}
        onClose={() => {
          setDeleteEmail('');
        }}
        open={!!deleteEmail}
        onDelete={unlinkEmail}
      />
      <PrettyCard>
        <BackArrow
          onClick={() => {
            navigate('/my-pages');
          }}
        />

        <div
          style={{
            marginTop: '20px',
            width: 'min(350, 90%)',
            alignSelf: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            alignContent: 'flex-end'
          }}>
          <Text text="Mina mejladresser" type="pageHeader.700" />
          <Text
            text="Kopplade mejladresser"
            type="h7.400"
            sx={{ opacity: 0.5, fontFamily: 'Inter' }}
          />
          <Space type="medium" />
          <Text text="Dina mejladresser" type="h7.500" />
          {model.emails.map((email) => (
            <TextField
              key={email}
              defaultValue={email}
              sx={{ marginBottom: '10px', width: 'min(300px, 100%)' }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment
                    position="end"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      setDeleteEmail(email);
                    }}>
                    <DeleteForeverIcon />
                  </InputAdornment>
                )
              }}
            />
          ))}
          <Space type="medium" />
          <Text text="+ Lägg till mejl" type="h7.700" />
          <Space type="medium" />
          <GoogleButton onClick={() => handleGoogleAuthClick()} text="Lägg till mejl via Google" />
          <Space type="large" />
        </div>
      </PrettyCard>
    </Box>
  );
});

export default EmailLinks;
