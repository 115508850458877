import './Space.css';
interface Props {
  type: string;
}

const Space = (props: Props) => {
  return <div className={'space_' + props.type}></div>;
};

export default Space;
