export interface RegisterUser {
  phone: string;
  firstName: string;
  lastName: string;
  SSN: string;
}
export enum StatusEnum {
  ACTIVE = 'active',
  WAITING = 'waiting',
  COMPLETE = 'complete'
}

export interface Profile {
  id: string;
  username: string;
  first_name: string;
  last_name: string;
  social_security_number: string;
  bank_number: string;
  phone_number: string;
  total_revenue: number;
  total_debt: number;
  total_paid: number;
  created_at: string;
  updated_at: string;
}

export interface SubTicket {
  id: string;
  train_num?: string;
  ticket_num?: string;
  train_company: string;
  expected_departure_time: string;
  expected_arrival_time: string;
  actual_departure_time: string;
  actual_arrival_time: string;
  departure_station: string;
  arrival_station: string;
  main_ticket_id: string;
  created_at: string;
  updated_at: string;

  /*
  "id": "74e93b2a-124b-44a2-bdd0-b892915b8861",
                "train_num": "43800",
                "ticket_num": "JYY4980E0006",
                "train_company": "SJ",
                "expected_departure_time": "14:25:00",
                "expected_arrival_time": "17:35:00",
                "actual_departure_time": null,
                "actual_arrival_time": null,
                "departure_station": "Göteborg C",
                "arrival_station": "Sthlm Central",
                "main_ticket_id": "b0ceb9fd-b468-4bb3-ad9c-ef1a90b4a331",
                "train_id": null,
                "created_at": "2024-07-20T18:27:12.142Z",
                "updated_at": "2024-07-20T18:27:12.142Z",
                "train": null*/
}

export interface MainTicket {
  id: string;
  first_name: string;
  last_name: string;
  validity_date?: Date;
  departure_station: string;
  arrival_station: string;
  booking_num?: string;
  is_resplus: boolean;
  thread_id?: string;
  message_id?: string;
  attachment_id?: string;
  price?: number;
  is_valid_case: boolean;
  status: StatusEnum;
  sub_tickets: SubTicket[];
  created_at: string;
  updated_at: string;
}
