import { Box } from '@mui/material';
import styles from '../../styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Text from './Text';

export default function BackArrow(props: { onClick: () => void }) {
  return (
    <Box>
      <div
        onClick={() => props.onClick()}
        style={{
          display: 'inline-flex',
          cursor: 'pointer',
          color: styles.gray
        }}>
        <ArrowBackIosNewIcon sx={{ color: '#808080' }} />
        <span>
          <Text text="Tillbaka" type="h7.500" sx={{ color: styles.gray }} />
        </span>
      </div>
    </Box>
  );
}
