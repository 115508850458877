const passwordPolicy = {
  minLength: 8,
  requireUppercase: true,
  requireLowercase: true,
  requireNumbers: true
  // requireSpecial: false
};

export const prettyDate = (unixDate: string) => {
  const date = new Date(Number.parseInt(unixDate));

  return date.toLocaleString('sv-SE', { day: 'numeric', month: 'long', year: 'numeric' });
};
export const prettyTime = (time: string) => {
  /*const date = new Date(Number.parseInt(unixDate));

  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Pad single digit minutes with a leading zero
  const m = minutes < 10 ? '0' + minutes : minutes;

  // Combine hours and minutes
  let formattedTime = `${hours}:${m}`;
  return formattedTime;*/

  return time.split(':').slice(0, 2).join(':');
};

export const googleScopes =
  'openid email profile ' +
  'https://www.googleapis.com/auth/userinfo.email ' +
  'https://www.googleapis.com/auth/userinfo.profile ' +
  'https://www.googleapis.com/auth/gmail.send ' +
  'https://www.googleapis.com/auth/gmail.readonly';

export const googleClientId =
  '509260630295-a01lagsv8ckhhig2qqfdqu2dclq2jj6l.apps.googleusercontent.com';

export const passwordRuleToText = (rule: string) => {
  switch (rule) {
    case 'minLength':
      return 'Lösenordet måste vara minst ' + passwordPolicy.minLength + ' tecken långt';
    case 'hasUppercase':
      return 'Lösenordet måste innehålla minst en stor bokstav';
    case 'hasLowercase':
      return 'Lösenordet måste innehålla minst en liten bokstav';
    case 'hasNumber':
      return 'Lösenordet måste innehålla minst en siffra';
    // case 'hasSpecial':
    //   return 'Password must contain at least one special character';
    default:
      return '';
  }
};

export const validatePassword = (password: string) => {
  const rules = {
    minLength: password.length >= passwordPolicy.minLength,
    hasUppercase: passwordPolicy.requireUppercase ? /[A-Z]/.test(password) : true,
    hasLowercase: passwordPolicy.requireLowercase ? /[a-z]/.test(password) : true,
    hasNumber: passwordPolicy.requireNumbers ? /[0-9]/.test(password) : true
    //hasSpecial: passwordPolicy.requireSpecial ? /[!@#$%^&*(),.?":{}|<>]/.test(password) : true
  };

  const isValid = Object.values(rules).every(Boolean);
  return {
    isValid,
    rules
  };
};

export const urlConstructor = (url: string, params: any) => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key) => urlParams.append(key, params[key]));
  return url + '?' + urlParams.toString();
};

export const getEmailProviderFromPath = (path: string) => {
  if (path.includes('google')) {
    return 'google';
  } else if (path.includes('microsoft')) {
    return 'microsoft';
  } else {
    return '';
  }
};
export function parseTime(time: string) {
  const [hours, minutes, seconds] = time.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
}

export function timeDifferenceInMinutes(time1: string, time2: string) {
  // Parse the time strings and convert them to seconds

  const seconds1 = parseTime(time1);
  const seconds2 = parseTime(time2);

  // Calculate the absolute difference in seconds
  let difference = Math.abs(seconds1 - seconds2);

  // Convert the difference to minutes
  const differenceInMinutes = difference / 60;

  return differenceInMinutes;
}

export function validatePersonnummer(personnummer: any) {
  // Ensure string in case of number
  personnummer = '' + personnummer;

  // Trim non-digits
  // This discards (and thus accepts) any non-digits anywhere in the personnummer.
  // Depending on your case, that might not be the desired behavior.
  personnummer = personnummer.replace(/[^\d]/g, '');

  // Return true if both a valid date and has a valid checksum
  return (
    hasValidLength(personnummer) && hasValidDate(personnummer) && hasValidChecksum(personnummer)
  );
}

function hasValidLength(personnummer: any) {
  // Ensure there's 10 or 12 digits
  return personnummer.length === 10 || personnummer.length === 12;
}

function hasValidDate(personnummer: any) {
  var date, year, month, day;

  // Pad it to 12 digits so we have a real year
  if (personnummer.length === 10) {
    personnummer = '19' + personnummer;
  }

  // Extract year/month/date and convert to number
  year = +personnummer.substring(0, 4);
  month = +personnummer.substring(4, 6) - 1; // Js-month is one less
  day = +personnummer.substring(6, 8);

  date = new Date(year, month, day);

  if (!+date) {
    return false;
  }

  // Now test that no year/month/day has overflowed. If it has, the date was invalid
  return year === date.getFullYear() && month === date.getMonth() && day === date.getDate();
}

function hasValidChecksum(numbers: any) {
  var n,
    i,
    l,
    checksum = 0;

  // Accept a 12-length personnummer, but always start with the two last digits
  // of the year
  for (i = numbers.length - 10, l = numbers.length; i < l; i++) {
    n = +numbers[i];

    if (i % 2 === 0) {
      checksum += ((n * 2) % 9) + ~~(n / 9) * 9;
    } else {
      checksum += n;
    }
  }

  return checksum % 10 === 0;
}
