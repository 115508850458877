import React from 'react';
import { Box } from '@mui/material';
import { fakeTickets } from '../../../utils/DummyTicketData';
import { MainTicket } from '../../../interfaces';
import TicketBox from '../TicketBox';
import Space from '../../common/Space';
import Text from '../../common/Text';
import styles from '../../../styles';
import PrettyButton from '../../PrettyButton';
import { Model } from '../../../Model';
interface FirstTimeViewModalProps {
  setShowEmailLinkingInfo: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFirstTimeView: React.Dispatch<React.SetStateAction<boolean>>;
  model: Model;
}

const FirstTimeViewModal: React.FC<FirstTimeViewModalProps> = ({ setShowEmailLinkingInfo, setShowFirstTimeView, model }) => {
  return (
    <div>
      <Box sx={{ position: 'relative' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '10px' }}>
          {fakeTickets.map((ticket: MainTicket) => (
            <TicketBox key={ticket.id} model={model} ticket={ticket} />
          ))}
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'absolute',
            zIndex: 5,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            width: 'min(90%, 600px)',
            height: 'auto',
            alignSelf: 'center',
            padding: 'min(20px, 2vw)',
            borderRadius: '10px',
            alignItems: 'center',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25);'
          }}>
          <Space type="large" />
          <img src="/icons/mailAndArrow.svg" />
          <Space type="large" />
          <Text
            text="Vidarebefordra dina tågbiljetter till "
            type="h7.400"
            sx={{ textAlign: 'center', fontFamily: 'Poppins' }}
            suffix={
              <span>
                    <b>biljett@klimra.com</b>
                  </span>
            }
          />
          <Text
            text="Läs mer"
            type="h7.600"
            sx={{
              color: styles.gray3,
              fontFamily: 'Inter',
              cursor: 'pointer',
              textDecorationLine: 'underline'
            }}
            onClick={() => setShowEmailLinkingInfo(true)}
          />

          <Space type="large" />

          <PrettyButton
            width="min(350px, 100%)"
            text={'Klart'}
            onClick={() => {
              setShowFirstTimeView(false);
              localStorage.setItem('showFirstTimeView', 'false');
            }}
          />
          <Space type="large" />

          {/*<Text
                text="Koppla din mejl för att se dina förseningsärenden."
                type="h7.400"
                sx={{ color: styles.gray3, fontFamily: 'Inter' }}
              />
              <Text
                text="Läs mer"
                type="h7.600"
                sx={{
                  color: styles.gray3,
                  fontFamily: 'Inter',
                  cursor: 'pointer',
                  textDecorationLine: 'underline'
                }}
                onClick={() => setShowEmailLinkingInfo(true)}
              />
              <Space type="large" />
              <Box
                sx={{
                  textAlign: 'center',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center'
                }}>
                <PrettyButton
                  width="min(350px, 100%)"
                  onClick={() => {
                    localStorage.setItem('redirect', '/my-pages/cases');
                    window.open(
                      urlConstructor('https://accounts.google.com/o/oauth2/auth', {
                        response_type: 'code',
                        client_id: googleClientId,
                        scope: googleScopes,
                        redirect_uri: window.location.origin + '/oauth/callback/google',
                        access_type: 'offline',
                        prompt: 'consent'
                      }),
                      '_self'
                    );
                  }}
                  text={'Länka mejladress via Google'}
                  backgroundColor={styles.mainbuttonColor}
                  color="white"
                  iconName="/icons/google.svg"
                />
              </Box>
              <Space type="large" />
              */}
        </Box>
      </Box>
    </div>
  );
};

export default FirstTimeViewModal;