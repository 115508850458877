import { observer } from 'mobx-react-lite';
import { Outlet, Link } from 'react-router-dom';
import { Model } from '../Model';
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography
} from '@mui/material';
import NavButton from './NavButton';
import PrettyButton from './PrettyButton';
import { useState } from 'react';
import CustomModal from './CustomModal';
import MenuIcon from '@mui/icons-material/Menu';
import styles from '../styles';

interface Props {
  model: Model;
}

const Header = observer((props: Props) => {
  const { model } = props;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: styles.headerColor,
          overflow: 'hidden',
          boxShadow: 'none',
          zIndex: '900'
        }}>
        <Container maxWidth="xl">
          <Toolbar disableGutters sx={{ height: '80px', gap: '20px' }}>
            <NavButton to="">
              <Box sx={{ padding: '2px' }}>
                <img
                  src="/logo.svg"
                  style={{
                    objectFit: 'contain',
                    height: '36px'
                  }}></img>
              </Box>
            </NavButton>
            <div style={{ flexGrow: 1, flex: 1 }} />
            <Box
              className="hideOnMobile"
              sx={{ display: 'flex', gap: '30px', height: 'fit-content', alignItems: 'center' }}>
              {model.profile ? (
                <>
                  <NavButton to="/my-pages/cases" text="Mina Ärenden"/>

                  <NavButton to="/my-pages" text="Inställningar" />
                </>
              ) : (
                <>
                  <NavButton to="/login" text="Logga in" />
                  <NavButton to="/signup" text="Skapa konto" />
                </>
              )}

              <NavButton to="/about-us" text="Om oss" />
              <NavButton to="/faq" text="FAQ" />
              <PrettyButton
                onClick={() => {
                  window.open('mailto:contact@klimra.com', '_self');
                }}
                backgroundColor="transparent"
                color="black"
                text="Kontakta oss"
                fontWeight={600}
              />
            </Box>

            <IconButton
              className="showOnMobile"
              edge="start"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        anchor="top"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          style: {
            width: '100%',
            position: 'absolute'
          }
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '20px',
            backgroundColor: styles.headerColor
          }}>
          {model.profile ? (
            <>
              <ListItem button component={Link} to="/my-pages/cases" onClick={toggleDrawer(false)}>
                <ListItemText primary="Mina Ärenden" />
              </ListItem>
              <ListItem button component={Link} to="/my-pages" onClick={toggleDrawer(false)}>
                <ListItemText primary="Inställningar" />
              </ListItem>
            </>
          ) : (
            <>
              <ListItem button component={Link} to="/login" onClick={toggleDrawer(false)}>
                <ListItemText primary="Logga in" />
              </ListItem>
              <ListItem button component={Link} to="/signup" onClick={toggleDrawer(false)}>
                <ListItemText primary="Skapa konto" />
              </ListItem>
            </>
          )}
          <ListItem button component={Link} to="/about-us" onClick={toggleDrawer(false)}>
            <ListItemText primary="Om oss" />
          </ListItem>
          <ListItem button component={Link} to="/faq" onClick={toggleDrawer(false)}>
            <ListItemText primary="FAQ" />
          </ListItem>
          <ListItem
            onClick={() => {
              window.open('mailto:contact@klimra.com', '_self');
            }}>
            <ListItemText primary="Kontakta oss" />
          </ListItem>
        </Box>
      </Drawer>
    </>
  );
});

export default Header;
