import React from 'react';
import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import styles from '../../../styles';
interface ProfileTabsProps {
  value: number;
  handleChange: (event: React.SyntheticEvent, newValue: number) => void;
}
const ProfileTabs:React.FC<ProfileTabsProps> = ({value, handleChange}) => {
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        {/* Tabs with green indicator color*/}
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              width: '100%',
              height: '7px',
              backgroundColor: '#D9D9D9',
              borderRadius: '4px',
              zIndex: 0
            }}
          />
          <Tabs
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: styles.mainbuttonColor,
                height: '7px',
                borderRadius: '4px'
              }
            }}>
            <Tooltip placement="top" title="Här ser du dina aktiva ärenden. ">
              <Tab
                label="Aktiva"
                sx={{
                  padding: '0px 10px',
                  minWidth: 'min(150px, 30vw)',
                  fontWeight: 'bold',
                  '&.Mui-selected': {
                    color: 'inherit'
                  }
                }}
              />
            </Tooltip>
            <Tooltip placement="top" title="Här ser du dina avslutade ärenden. ">
              <Tab
                label="Avslutade"
                sx={{
                  padding: '0px 10px',
                  minWidth: 'min(150px, 30vw)',
                  fontWeight: 'bold',
                  '&.Mui-selected': {
                    color: 'inherit'
                  }
                }}
              />
            </Tooltip>
            <Tooltip
              placement="top-start"
              title="Här ser du ärenden där du inte är berättigad kompensation.">
              <Tab
                label="Övrigt"
                sx={{
                  padding: '0px 10px',
                  minWidth: 'min(150px, 30vw)',
                  fontWeight: 'bold',
                  '&.Mui-selected': {
                    color: 'inherit'
                  }
                }}
              />
            </Tooltip>
          </Tabs>
        </div>
      </Box>
    </div>
  );
};

export default ProfileTabs;