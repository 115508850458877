import { Box, Button, Checkbox, Divider, InputAdornment, TextField } from '@mui/material';
import styles from '../../styles';
import PrettyButton from '../PrettyButton';
import { Model } from '../../Model';
import { RegisterUser } from '../../interfaces';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { validatePersonnummer, validatePassword } from '../../utils/Tools';
import toast from 'react-hot-toast';
import { CheckBox } from '@mui/icons-material';
import Text from '../common/Text';
import Space from '../common/Space';
import ProxyInfoPopup, { DynamicProxyPDF } from '../popups/ProxyInfoPopup';
import { acceptedTrainCompanies } from '../../utils/Constants';
import { pdf } from '@react-pdf/renderer';

interface Props {
  model: Model;
  userState: RegisterUser;
  setUserState: (userState: RegisterUser) => void;
  setShowCongratsPopup: (show: boolean) => void;
}

const DetailsForm = observer((props: Props) => {
  const { model, userState, setUserState, setShowCongratsPopup } = props;

  const [showProxyPopup, setShowProxyPopup] = useState(false);
  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);

  const [validSSN, setValidSSN] = useState(undefined) as any;

  const register = async (e: any) => {
    e.preventDefault();
    setShowCongratsPopup(true);
    const blobs: Blob[] = [];
    const steps = acceptedTrainCompanies;

    for (let i = 0; i < steps.length; i++) {
      const doc = (
        <DynamicProxyPDF
          company={steps[i]}
          SSN={userState.SSN}
          name={userState.firstName + ' ' + userState.lastName}
        />
      );
      const blob = await pdf(doc).toBlob();
      blobs.push(blob);
    }
    model.signUp(userState, blobs);
  };

  const onChange = (attributeName: string, value: string) => {
    if (attributeName === 'SSN') {
      setValidSSN(validatePersonnummer(value));
    }
    setUserState({ ...userState, [attributeName]: value });
  };

  return (
    <Box
      component="form"
      onSubmit={register}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: 'min(90%, 400px)',
        height: 'fit-content',
        alignSelf: 'center'
      }}>
      <ProxyInfoPopup
        model={model}
        open={showProxyPopup}
        onClose={() => setShowProxyPopup(false)}
        SSN={userState.SSN}
        name={`${userState.firstName} ${userState.lastName}`}
      />

      <Text text="Snart klar" type="pageHeader.700" />
      <Text
        text="Helt gratis. Säg upp kontot när du vill."
        type="h7.400"
        sx={{ opacity: 0.5, fontFamily: 'Inter' }}
      />

      <Space type="large" />

      <TextField
        placeholder="Förnamn"
        label="Förnamn"
        required
        value={userState.firstName}
        onChange={(e) => onChange('firstName', e.target.value)}
      />
      <Space type="medium" />
      <TextField
        placeholder="Efternamn"
        label="Efternamn"
        value={userState.lastName}
        required
        onChange={(e) => onChange('lastName', e.target.value)}
      />
      <Space type="medium" />

      <TextField
        placeholder="Personnummer"
        label="Personnummer"
        required
        value={userState.SSN}
        onChange={(e) =>
          (!isNaN(e.target.value as any) || e.target.value === '') &&
          onChange('SSN', e.target.value)
        }
      />
      {validSSN === false ? (
        <Text
          text="Ogiltigt personnummer"
          type="h7.400"
          sx={{ color: 'red', fontFamily: 'Inter' }}
        />
      ) : null}

      <Space type="medium" />

      <TextField
        placeholder="Mobilnummer"
        label="Mobilnummer"
        type="tel"
        required
        InputProps={{
          startAdornment: <InputAdornment position="start">+46</InputAdornment>
        }}
        value={userState.phone}
        onChange={(e) => {
          if (e.target.value === '') return onChange('phone', '');
          const num = Number.parseInt(e.target.value.replace(' ', '')).toString();
          if (num === 'NaN') return;
          onChange('phone', num);
        }}
      />
      {userState.phone.length !== 9 && userState.phone.length !== 0 ? (
        <Text
          text="Ogiltigt telefonnummer"
          type="h7.400"
          sx={{ color: 'red', fontFamily: 'Inter' }}
        />
      ) : null}

      <Space type="large" />

      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Checkbox
          checked={checkbox1}
          onChange={() => setCheckbox1(!checkbox1)}
          sx={{
            padding: '0px',
            '&.Mui-checked': {
              color: styles.headerColor
            }
          }}
        />
        <Box>
          <Text
            type="h8.400"
            text="Jag har läst igenom och godkänt användarvillkoren."
            sx={{ color: styles.gray2, fontSize: '10px' }}
          />
          <a href="/user-terms" target="_blank">
            <Text
              type="h8.600"
              text="Läs mer"
              sx={{ color: styles.gray2, fontSize: '10px', textDecorationLine: 'underline' }}
            />
          </a>
        </Box>
      </Box>
      <Space type="small" />

      <Box sx={{ display: 'flex', gap: '10px' }}>
        <Checkbox
          checked={checkbox2}
          onChange={() => setCheckbox2(!checkbox2)}
          sx={{
            padding: '0px',
            '&.Mui-checked': {
              color: styles.headerColor
            }
          }}
        />
        <Box>
          <Text
            type="h8.400"
            text="Jag ger Klimra fullmakt att driva mina kompensationsärenden."
            sx={{ color: styles.gray2, fontSize: '10px' }}
          />
          <Text
            onClick={() => setShowProxyPopup(true)}
            type="h8.600"
            text="Läs mer"
            sx={{
              color: styles.gray2,
              fontSize: '10px',
              textDecorationLine: 'underline',
              cursor: 'pointer'
            }}
          />
        </Box>
      </Box>

      <Space type="large" />

      <PrettyButton
        disabled={
          !checkbox2 ||
          !checkbox1 ||
          validSSN === false ||
          userState.SSN === '' ||
          !(userState.phone.length === 9)
        }
        type="submit"
        text="Skapa konto"
        sx={{ width: 'auto', padding: '15px 0px' }}
        className="hover:opacity-50"
      />
    </Box>
  );
});

export default DetailsForm;
