import { Box, Link } from '@mui/material';
import { useEffect, useState } from 'react';
import Text from './Text';

const CookieBanner = () => {
  const [isCookieBannerVisible, setIsCookieBannerVisible] = useState(false);

  const handleAcceptCookies = () => {
    setIsCookieBannerVisible(false);
    localStorage.setItem('cookieBanner', 'accepted');
  };

  useEffect(() => {
    const cookieBanner = localStorage.getItem('cookieBanner');
    if (cookieBanner === 'accepted') {
      setIsCookieBannerVisible(false);
    } else {
      setIsCookieBannerVisible(true);
    }
  }, []);

  return (
    <>
      {isCookieBannerVisible && (
        <Box
          sx={{
            position: 'fixed',
            backgroundColor: 'rgba(151, 151, 151, 0.70);',
            bottom: '0px',
            left: '0px',
            right: '0px',
            height: '100px',
            display: 'flex',
            zIndex: 999,
            justifyContent: 'center',
            alignItems: 'center',
            padding: '0px 10vw',
            gap: '5vw'
          }}>
          <Text
            sx={{ color: 'black' }}
            suffix={
              <a
                href="/privacy-policy"
                target="_blank"
                style={{ color: 'black', textDecorationLine: 'underline' }}>
                Privacy Policy
              </a>
            }
            type="h7.400"
            text="Vi använder cookies för att förbättra de tjänster vi erbjuder, öka säkerheten och hantera inloggningar. Du kan stänga av cookies i din browser om du inte vill att vi använder cookies. "
          />
          <img
            src="/icons/close_x.svg"
            style={{ cursor: 'pointer' }}
            onClick={() => handleAcceptCookies()}></img>
        </Box>
      )}
    </>
  );
};

export default CookieBanner;
