import { Box } from '@mui/material';

interface Props {
  children?: any;
  sx?: any;
  goBackPath?: string;
}

const PrettyCard = (props: Props) => {
  const { goBackPath } = props;
  return (
    <Box
      className="mobileInvisibleBox"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        padding: !!goBackPath ? '20px 40px' : '50px 40px',
        width: 'min(90vw, 600px)',
        borderRadius: '20px',
        boxShadow: '0px 0px 30px 0px rgba(205, 198, 219, 0.5)',
        ...props.sx
      }}>
      {props.children}
    </Box>
  );
};

export default PrettyCard;
