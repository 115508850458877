import React, { useState, useEffect } from 'react';

const HTMLRenderer = (props: { htmlFilePath: string }) => {
  const { htmlFilePath } = props;
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    const fetchHTML = async () => {
      try {
        const response = await fetch(htmlFilePath);
        const text = await response.text();
        setHtmlContent(text);
      } catch (error) {
        console.error('Error fetching the HTML file:', error);
      }
    };

    fetchHTML();
  }, [htmlFilePath]);

  return <div style={{ maxWidth: '1200px' }} dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default HTMLRenderer;
