export default {
  mainBoxColor: '#F4D1D0',
  mainbuttonColor: '#9A4747',
  gray: '#454545',
  lightGray: '#B0B0B0',

  casesBox: '#D6E1D4',
  casesGray: '#7C7B7B',
  headerColor: '#719394',

  boldGray: '#636364',

  darkGray: '#3B3B3B',

  gray2: '#808080',
  gray3: '#404040',

  tutorialBoxTitle: '#343434',
  tutorialBoxText: '#464646',
  tutorialBox: '#D6E1D4'
};
