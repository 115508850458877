import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import CustomModal from '../CustomModal';
import PrettyButton from '../PrettyButton';
import { useState } from 'react';
import styles from '../../styles';

interface Props {
  open: boolean;
  onClose: () => void;
  onDelete: () => void;
}

const DeleteAccountConfirmation = (props: Props) => {
  const { open, onClose, onDelete } = props;

  const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
  return (
    <CustomModal title="Radera konto" isOpen={open} onClose={onClose}>
      <CustomModal
        title="Radera konto"
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}>
        <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <h4>Är du säker på att du vill radera ditt konto?</h4>
          <Box sx={{ display: 'flex', gap: '20px', marginTop: '10px' }}>
            <PrettyButton
              text="Ja, jag är säker"
              backgroundColor="transparent"
              color="black"
              onClick={() => {
                onDelete();
              }}
            />
            <PrettyButton
              text="Nej"
              onClick={() => setShowLogoutConfirmation(false)}
              sx={{ minWidth: 'none' }}
            />
          </Box>
        </Box>
      </CustomModal>
      <h4 style={{ color: styles.gray }}>
        <b>Hur raderar jag mitt konto?</b>
      </h4>
      <p style={{ color: styles.gray }}>
        Ifall du raderar ditt konto kommer Klimra inte längre att kunna driva dina
        kompensationsärenden mot tågbolagen.
      </p>
      <Box sx={{ display: 'flex', gap: '20px', margin: '50px auto 0px' }}>
        <PrettyButton
          text="Jag vill radera mitt konto"
          backgroundColor="transparent"
          color={styles.gray}
          onClick={() => {
            setShowLogoutConfirmation(true);
          }}
        />
      </Box>
    </CustomModal>
  );
};

export default DeleteAccountConfirmation;
