import { Backdrop, Box, CircularProgress, Modal } from '@mui/material';
const style = {
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '20vw',
  height: '20vw'
};

const Loader = () => {
  return (
    <Backdrop
      sx={{ pointerEvents: 'none', color: '#ccc', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default Loader;
