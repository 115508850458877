import { observer } from 'mobx-react-lite';
import { Model } from '../../Model';
import { Box, Button, CircularProgress, MobileStepper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { RegisterUser } from '../../interfaces';
import { Link, useNavigate } from 'react-router-dom';
import styles from '../../styles';
import DetailsForm from './DetailsForm';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import LinkEmail from './LinkEmail';
import BackArrow from '../common/BackArrow';
import { openGoogleAuth } from '../../utils/AuthHelper';
import Loader from '../Loader';
import PrettyCard from '../common/PrettyCard';
import AccountCongratsPopup from '../popups/AccountCongratsPopup';

interface Props {
  model: Model;
}

const Register = observer((props: Props) => {
  const { model } = props;

  const [stage, setStage] = useState(0);
  const [loading, isLoading] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [didPressRegisterButton, setDidPressRegisterButton] = useState(false);

  const userState =
    model.registerUser ||
    ({
      phone: '',
      firstName: '',
      lastName: '',
      SSN: ''
    } as RegisterUser);
  const setUserState = (userState: RegisterUser) => (model.registerUser = userState);

  const [showCongratsPopup, setShowCongratsPopup] = useState(false);

  const navigate = useNavigate();
  useEffect(() => {
    const authed = model.isSessionValid();

    if (!authed) {
      const redirect = localStorage.getItem('redirect');
      console.log('redirect', redirect);

      if (redirect) {
        localStorage.removeItem('redirect');
        return navigate(redirect);
      }
      return navigate('/login');
    }
    if (model.profile) {
      if (didPressRegisterButton) {
        setShowCongratsPopup(true);
      } else {
        return navigate('/my-pages/cases');
      }
    }
  }, [model.profile]);

  if (!model.isAuthed || (model.isAuthed && model.profile === undefined)) return <Loader />;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'flex',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        height: 'auto'
      }}>
      <AccountCongratsPopup
        open={showCongratsPopup}
        onClose={function (): void {}}
        onAccept={function (): void {
          localStorage.removeItem('createdAccountTime');
          model.toggleRefresh();
          navigate('/my-pages/cases');
        }}
      />
      <PrettyCard>
        <BackArrow
          onClick={() => {
            model.signOut();
          }}
        />

        <DetailsForm
          model={model}
          userState={userState}
          setUserState={setUserState}
          setShowCongratsPopup={setShowCongratsPopup}
        />
        <MobileStepper
          variant="dots"
          steps={2}
          position="static"
          activeStep={1}
          color={styles.headerColor}
          sx={{
            maxWidth: 400,
            margin: '20px auto',
            backgroundColor: 'transparent',
            color: styles.headerColor,

            '.MuiMobileStepper-dotActive': { backgroundColor: styles.headerColor }
          }}
          backButton={undefined}
          nextButton={undefined}
        />
      </PrettyCard>
    </Box>
  );
});

export default Register;
