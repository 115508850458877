import { MainTicket, Profile, RegisterUser, SubTicket } from './interfaces';
import {
  acceptTicket,
  apiSignUp, apiUrl,
  checkUsername,
  deleteMyProfile,
  exchangeCode,
  getMyEmails,
  getMyProfile,
  getMyTickets,
  linkEmail,
  saveMyProfile,
  unlinkEmail
} from './utils/API';
import toast from 'react-hot-toast';
import { Navigate } from 'react-router-dom';
import { isSessionValid } from './utils/AuthHelper';

export const model = {
  hasInit: false as boolean,
  profile: undefined as Profile | undefined | null,
  tickets: undefined as MainTicket[] | undefined,
  isAuthed: false as boolean,

  registerUser: undefined as RegisterUser | undefined,

  isMobile: false as boolean,

  showCongratsPopup: false as boolean,

  emails: undefined as string[] | undefined,

  refreshy: false as boolean,

  toggleRefresh: function () {
    this.refreshy = !this.refreshy;
  },

  signOut: async function () {
    console.log('signing out');
    const redirect = localStorage.getItem('redirect');
    if (redirect) {
      localStorage.removeItem('redirect');
      window.location.pathname = redirect;
    }
    await fetch(apiUrl + '/auth/signout', {
      method: 'POST',
      credentials: 'include', // Include cookies in the request
    });
    this.profile = undefined;
    this.tickets = undefined;
    this.emails = undefined;
    this.isAuthed = false;
    // localStorage.removeItem('accessToken');
    // localStorage.removeItem('idToken');
    // localStorage.removeItem('refreshToken');
  },

  signUp: async function (user: RegisterUser, blobs: Blob[]) {
    const response = await apiSignUp(user, blobs);
    if (!response) {
      return toast.error('Error signing up: ');
    }
  },

  exchangeCode: async function (code: string, provider: string, redirectURI: string) {
    const resp = await exchangeCode(code, provider, redirectURI);
    if (resp.error) {
      return toast.error('Ett fel uppstod: ' + resp.error);
    }
    // const { accessToken, idToken, refreshToken } = codes;
    // if (!accessToken || !idToken || !refreshToken) {
    //   return toast.error('Ett fel uppstod: ' + codes.error);
    // }
    // localStorage.setItem('accessToken', accessToken);
    // localStorage.setItem('idToken', idToken);
    // localStorage.setItem('refreshToken', refreshToken);
    // localStorage.setItem('provider', provider);
    //   We don't need to set the tokens here, since we just have everything stored in cookies now
  },

  validateUsername: async function (username: string) {
    const isValid = await checkUsername(username);
    if (!isValid || isValid.error) {
      return false;
    }
    return true;
  },
  isSessionValid: async function () {
    const valid = await isSessionValid();
    this.isAuthed = valid;
    return valid;
  },

  loadMyEmails: async function () {
    const emails = await getMyEmails();
    if (emails.error) {
      if (emails.authenticated === false) {
        this.signOut();
      }
      return;
    }
    this.emails = emails;
  },

  loadMyTickets: async function () {
    const response = await getMyTickets();
    if (response.error) {
      if (response.authenticated === false) {
        this.signOut();
      }
      return;
    }
    console.log('settings tickets', response);

    this.tickets = response;
  },

  deleteMyProfile: async function () {
    const response = await deleteMyProfile();
    if (response.error) {
      if (response.authenticated === false) {
        this.signOut();
      }
      return;
    }
    toast.success('Profil raderad');
    this.signOut();
  },

  loadMyProfile: async function () {
    const response = await getMyProfile();
    if (response.error === 'user not registered') {
      console.log('oamwdomalsd');

      this.profile = null;
      return;
    }
    if (response.error) {
      if (response.authenticated === false) {
        localStorage.setItem('redirect', '/signup');
        console.log('Not authed PL', response);

        this.signOut();
      }
      return;
    }
    this.profile = response;
  },
  saveMyProfile: async function (newProfile: Profile) {
    if (!newProfile) {
      return toast.error('Error, no profile');
    }
    const response = await saveMyProfile(newProfile);
    if (!response || response.error) {
      return toast.error('Det gick inte att spara profilen: ' + response.error);
    }
    toast.success('Profil sparad!');
    this.profile = response;
  },
  linkEmail: async function (code: string, provider: string, redirectURI: string) {
    const mail = await linkEmail(code, provider, redirectURI);
    if (mail.error) {
      return toast.error('Ett fel uppstod: ' + mail.error);
    }
    toast.success('Email Länkad!');
    this.emails = this.emails ? this.emails.concat(mail) : [mail];
  },
  unlinkEmail: async function (email: string) {
    const response = await unlinkEmail(email);
    if (response.error) {
      return toast.error('Error unlinking email: ' + response.error);
    }
    this.emails = this.emails?.filter((e) => e !== email);
    toast.success('Email olänkad!');
  },
  acceptTicket: async function (ticket: MainTicket) {
    const response = await acceptTicket(ticket.id);
    if (response.error) {
      return toast.error('Error accepting ticket: ' + response.error);
    }
    toast.success('Ärende mottaget!');
    //Replace ticket in list

    const newTickets = this.tickets?.map((t) => {
      if (t.id === ticket.id) {
        return { ...t, status: response.status };
      }
      return t;
    });
    this.tickets = newTickets;
  }
};

export type Model = typeof model;
