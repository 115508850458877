import { useEffect, useState } from 'react';
import Loader from './Loader';
import { Model } from '../Model';
import { getEmailProviderFromPath } from '../utils/Tools';
import { useNavigate } from 'react-router-dom';

interface Props {
  model: Model;
  isAuth?: boolean;
}

const OAuthCallback = (props: Props) => {
  const { model, isAuth } = props;
  const navigate = useNavigate();
  useEffect(() => {
    const f = async () => {
      const redirect = localStorage.getItem('redirect');

      const code = new URLSearchParams(window.location.search).get('code') ?? ''; //This could be manipulated by the user to inject
      //TODO: Add URL Filter
      const redirectURI = window.location.origin + window.location.pathname;
      const provider = getEmailProviderFromPath(redirectURI);

      if (isAuth === true) {
        await props.model.exchangeCode(
          code,
          getEmailProviderFromPath(window.location.pathname),
          redirectURI
        ).catch((e) => {
          console.log('Error: ', e);
        });

        navigate('/login');
        window.location.reload();
      } else {
        //Email link

        await model.linkEmail(code, provider, redirectURI);
        if (redirect) {
          localStorage.removeItem('redirect');
          return navigate(redirect);
        }
        navigate('/my-pages/emails');
      }
      window.location.reload();
    };
    f();

    // window.close();
  }, []);
  return (
    <div>
      <Loader></Loader>
    </div>
  );
};

export default OAuthCallback;
