import { Box } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Text from '../common/Text';
import Space from '../common/Space';
import PersonIcon from '@mui/icons-material/Person';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
interface Props {
  icon: string;
  title: string;
  description: string;
  onClick?: () => void;
}

const ProfilePageOption = (props: Props) => {
  const { icon, title, description } = props;
  let isPerson = false;
  if (icon === 'person') isPerson = true;
  return (
    <Box
      onClick={props.onClick}
      sx={{ display: 'flex', width: '100%', gap: '10px', cursor: 'pointer' }}>
      <Box sx={{ width: '30px', display: 'flex', justifyContent: 'center' }}>
        {isPerson ? (
          <Box
            sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <PersonIcon sx={{ color: '#808080', fontSize: '35px' }} />
          </Box>
        ) : (
          <img style={{ objectFit: 'contain', width: '100%' }} src={props.icon} />
        )}
      </Box>
      <Box sx={{ flex: '1', padding: '25px 10px' }}>
        <Text text={props.title} type="h7.600" sx={{ fontFamily: 'Inter' }} />
        <div style={{ height: '5px' }} />
        <Text text={props.description} type="h7.400" sx={{ color: '#7C7B7B' }} />
      </Box>
      <Box sx={{ width: '22px', display: 'flex', justifyContent: 'center' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '30px'
          }}>
          <ArrowForwardIosIcon sx={{ color: '#808080', fontSize: '22px' }} />
        </Box>
        {/*<img style={{ objectFit: 'contain', width: '100%' }} src="/icons/arrow_right.svg" />*/}
      </Box>
    </Box>
  );
};

export default ProfilePageOption;
