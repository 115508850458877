import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import styles from '../../styles';
import { prettyDate, prettyTime } from '../../utils/Tools';
import Text from '../common/Text';
interface Props {
  departureLocation: string;
  arrivalLocation: string;

  departureTime: string;
  arrivalTime: string;
  actualArrivalTime: string;
  actualDepartureTime: string;
}
const TrainSchedule = (props: Props) => {
  const {
    departureLocation,
    arrivalLocation,
    departureTime,
    arrivalTime,
    actualArrivalTime,
    actualDepartureTime
  } = props;

  const myGray = '#AEAEAE';

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '5px',
        alignItems: 'end'
      }}>
      <Text text={departureLocation} type="h8.600" sx={{ color: '#3B3B3B' }} />
      <Box sx={{ flex: '1' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                flexDirection: 'column'
              }}>
              {actualDepartureTime === departureTime ? (
                <h4 style={{ margin: '0px' }}>{prettyTime(departureTime)}</h4>
              ) : (
                <>
                  <h4 style={{ margin: '0px', textDecorationLine: 'line-through' }}>
                    {prettyTime(departureTime)}
                  </h4>
                  <h4 style={{ margin: '0px' }}>{prettyTime(actualDepartureTime)}</h4>
                </>
              )}
            </Box>
            <Box sx={{ flex: '1' }} />
            <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
              {actualArrivalTime === arrivalTime ? (
                <h4 style={{ margin: '0px' }}>{prettyTime(arrivalTime)}</h4>
              ) : (
                <>
                  <h4 style={{ margin: '0px', textDecorationLine: 'line-through' }}>
                    {prettyTime(arrivalTime)}
                  </h4>
                  <h4 style={{ margin: '0px' }}>{prettyTime(actualArrivalTime)}</h4>
                </>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0px 8px'
            }}>
            <Box
              sx={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: 'white',
                border: '2px solid ' + myGray
              }}
            />
            <Box sx={{ flex: '1', height: '2px', backgroundColor: myGray }} />
            <Box
              sx={{
                width: '25px',
                height: '25px',
                borderRadius: '50%',
                backgroundColor: 'white',
                border: '2px solid ' + myGray
              }}
            />
          </Box>
        </Box>
      </Box>
      <Text text={arrivalLocation} type="h8.600" sx={{ color: '#3B3B3B' }} />
    </Box>
  );
};

export default TrainSchedule;
