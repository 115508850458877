import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Model } from '../../Model';
import BackArrow from '../common/BackArrow';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles';
import Loader from '../Loader';
import { useEffect, useState } from 'react';
import TicketBox from './TicketBox';
import { MainTicket, StatusEnum } from '../../interfaces';
import PrettyButton from '../PrettyButton';
import { googleClientId, googleScopes, urlConstructor } from '../../utils/Tools';
import Text from '../common/Text';
import Space from '../common/Space';
import { fakeTickets } from '../../utils/DummyTicketData';
import EmailLinkingInfoPopup from '../popups/EmailLinkingInfoPopup';
import './CasesPage.css';
import AccountCongratsPopup from '../popups/AccountCongratsPopup';
import { Image } from '@mui/icons-material';
import NoTicketsMessage from './ProfileSpecialMessages/NoTicketsMessage';
import FirstTimeViewModal from './ProfileModals/firstTimeViewModal';
import ProfileFooterMessage from './ProfileSpecialMessages/ProfileFooterMessage';
import ProfileTabs from './ProfileModals/ProfileTabs';

interface Props {
  model: Model;
}

const CasesPage = observer((props: Props) => {
  const devModeEnabled: boolean = false; //used for testing, TODO: Remove
  const { model } = props;
  const navigate = useNavigate();
  const [showEmailLinkingInfo, setShowEmailLinkingInfo] = useState(false);

  const [hadTimeToCheckEmails, setHadTimeToCheckEmails] = useState(false);

  const [showFirstTimeView, setShowFirstTimeView] = useState(
    (localStorage.getItem('showFirstTimeView') || 'true') === 'true' ? true : false
  );

  const [value, setValue] = useState(0);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (model.emails) return;

    model.loadMyEmails();
  }, []);

  useEffect(() => {
    if (model.tickets?.length || 0 !== 0) {
      setHadTimeToCheckEmails(true);
    }
    if (model.tickets) {
      return;
    }

    model.loadMyTickets();
  }, [model.tickets]);

  useEffect(() => {
    const createdAccountTime = localStorage.getItem('createdAccountTime');
    if (createdAccountTime) {
      const time = new Date(createdAccountTime);
      const now = new Date();
      const diff = now.getTime() - time.getTime();
      // If the user has had time to check emails (5 minutes)
      const delayMinutes = 5;
      if (diff > 1000 * 60 * delayMinutes) {
        setHadTimeToCheckEmails(true);
      } else {
        setTimeout(() => {
          setHadTimeToCheckEmails(true);
          model.toggleRefresh();
        }, 1000 * 60 * delayMinutes - diff);
      }
    } else {
      localStorage.setItem('createdAccountTime', new Date().toISOString());
    }
  }, []);

  useEffect(() => {
    let int: any;
    if (!hadTimeToCheckEmails) {
      int = setInterval(() => {
        model.loadMyTickets();
      }, 5000);
    }

    return () => {
      clearInterval(int);
    };
  }, [hadTimeToCheckEmails]);

  if (!model.tickets) return <Loader />;

  const filteredTickets = model.tickets
    .filter((ticket) => {
      if (!ticket.status) return false;

      if (ticket.is_valid_case === false) return value === 2;

      if (ticket.status === StatusEnum.WAITING || ticket.status === StatusEnum.ACTIVE) {
        return value === 0;
      } else if (ticket.status === StatusEnum.COMPLETE) {
        return value === 1;
      }
      return false;
    })
    .sort((a, b) => {
      return new Date(b.validity_date || 0).getTime() - new Date(a.validity_date || 0).getTime();
    });

  return (
    <Box
      sx={{
        width: 'min(100vw, 1000px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '15px'
      }}>
      <EmailLinkingInfoPopup
        open={showEmailLinkingInfo}
        onClose={() => setShowEmailLinkingInfo(false)}
        model={model}
      />
      {/*model.emails?.length === 0 && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 3,
            backdropFilter: 'blur(3px)'
          }}
        />
      )*/}
      {showFirstTimeView && (
        <Box
          sx={{
            position: 'absolute',
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 3,
            backdropFilter: 'blur(3px)'
          }}
        />
      )}
      <Text text="Mina ärenden" type="h4.600" sx={{ textAlign: 'center', fontFamily: 'Poppins' }} />
      <Space type="large" />
      <ProfileTabs value={value} handleChange={handleChange} />
      {/*Profiletabs are now refactored into a new component file /Nima*/}
      {
        /*model.emails?.length === 0*/ showFirstTimeView ? (
          <FirstTimeViewModal setShowEmailLinkingInfo={setShowEmailLinkingInfo} setShowFirstTimeView={setShowFirstTimeView} model={model} />
        //   Refactored this part /Nima
        ) : (
          <>
            {filteredTickets.length === 0 ? (
              <>
                {hadTimeToCheckEmails ? (
                  <>
                    <NoTicketsMessage />
                  {/*  Moved the long logic to a new file /Nima*/}
                  </>
                ) : (
                  <>
                    {/* Loader */}
                    <Space type="xlarge" />
                    <div className="loader-wrapper">
                      <div className="ball" style={{ backgroundColor: styles.headerColor }}></div>
                      <div className="ball" style={{ backgroundColor: styles.headerColor }}></div>
                      <div className="ball" style={{ backgroundColor: styles.headerColor }}></div>
                    </div>
                    <Space type="xlarge" />
                  </>
                )}
              </>
            ) : (
              <h4 style={{ color: styles.lightGray }}>Klicka på ärendet för att se mer.</h4>
            )}{' '}
            {filteredTickets.map((ticket: MainTicket) => (
              <TicketBox key={ticket.id} model={model} ticket={ticket} />
            ))}
            <ProfileFooterMessage />
          {/*  Refactored /Nima*/}
          </>
        )
      }

      <Box sx={{ flex: 1 }}></Box>
    </Box>
  );
});

export default CasesPage;
