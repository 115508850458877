import React, { useEffect, useState } from 'react';
import { Model } from './Model';
import { observer } from 'mobx-react-lite';
import {
  BrowserRouter,
  Routes,
  Route,
  useNavigate,
  useNavigation,
  useLocation
} from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/home/Home';
import Register from './components/register/Register';
import Login from './components/Login';
import toast, { Toaster } from 'react-hot-toast';
import OAuthCallback from './components/OAuthCallback';
import MyPages from './components/profile/MyPages';
import Loader from './components/Loader';
import DetailsPage from './components/profile/DetailsPage';
import AboutUs from './components/about-us/AboutUs';
import FAQ from './components/faq/FAQ';
import { isSessionValid } from './utils/AuthHelper';
import { refreshTokens } from './utils/API';
import ProxyInfoPopup from './components/popups/ProxyInfoPopup';
import AccountCongratsPopup from './components/popups/AccountCongratsPopup';
import OnLoad from './components/misc/OnLoad';
import HTMLRenderer from './components/common/HTMLRenderer';
import CookieBanner from './components/common/CookieBanner';

interface Props {
  model: Model;
}

const App = observer((props: Props) => {
  const { model } = props;

  //const Auth = useAuthenticator();
  const [hasCheckedAuth, setHasCheckedAuth] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      const authed = await model.isSessionValid();
      setHasCheckedAuth(true);
      if (authed) {
        await model.loadMyProfile();
      } else {
        const resp = await refreshTokens();
        if (resp.error) {
          console.error('Error refreshing tokens: ', resp.error);
          return;
        }
        checkAuthentication();
      }
    };
    checkAuthentication();
  }, [model.refreshy]);

  /*useEffect(() => {
    if (location.pathname.includes('/callback')) {
      model.hasInit = true;
    }
    const x = async () => {
      if (model.isAuthed) {
        await model.loadMyProfile();
        model.hasInit = true;
      } else {
        model.hasInit = true;
      }
    };

    x();
  }, [model.isAuthed]);
  //if (model.isAuthed && model.profile === undefined) return <Loader />;
  if (!model.hasInit) return <Loader />;*/
  if (!hasCheckedAuth) return <Loader />;
  return (
    <>
      <OnLoad />
      <Toaster />
      <CookieBanner />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout model={model} />}>
            <Route
              index
              element={model.profile ? <MyPages model={model} /> : <Home model={model} />}
            />
            <Route path="register" element={<Register model={model} />} />
            <Route path="signup" element={<Login model={model} />} />

            <Route path="login" element={<Login model={model} />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="faq" element={<FAQ />} />

            <Route path="my-pages" element={<MyPages model={model} />}>
              <Route path="*" element={<MyPages model={model} />} />
            </Route>

            <Route
              path="privacy-policy"
              element={<HTMLRenderer htmlFilePath="/privacy_policy_se.html" />}
            />
            <Route
              path="user-terms"
              element={<HTMLRenderer htmlFilePath="/TOS_se.html"></HTMLRenderer>}
            />
            <Route
              path="cookies"
              element={<HTMLRenderer htmlFilePath="/cookies_se.html"></HTMLRenderer>}
            />

            <Route path="/oauth/callback/*" element={<OAuthCallback model={model} />} />
            <Route
              path="/oauthLogin/callback/*"
              element={<OAuthCallback model={model} isAuth={true} />}
            />

            <Route path="*" element={<>404 not found</>} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
});

export default App;
