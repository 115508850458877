import { Box } from '@mui/material';
import styles from '../../styles';
import Text from '../common/Text';
import Space from '../common/Space';

interface Props {
  image: string;
  text: string;
  title: string;
  hasArrow?: boolean;
}

const HomeTutorialBox = (props: Props) => {
  const { image, text, title, hasArrow } = props;
  return (
    <Box
      className="rowToScrollRowItem"
      sx={{
        backgroundColor: styles.tutorialBox,
        padding: '0px 20px'
      }}>
      <Space type="xlarge" />

      <Box sx={{ flex: 1, display: 'flex', width: '100%', alignItems: 'center' }}>
        <Box sx={{ flex: 1, flexGrow: 1 }}></Box>
        <img style={{ height: '165px', maxWidth: '90%', objectFit: 'contain' }} src={props.image} />
        <Box sx={{ flex: '1', display: 'flex', justifyContent: 'flex-end' }}>
          {props.hasArrow && (
            <Box>
              <img src="/icons/tutorialArrow.svg" style={{ marginLeft: 'min(0,1vw, 10px)' }}></img>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Space type="large" />
        <Text
          type="h6.600"
          sx={{ color: styles.tutorialBoxTitle, fontFamily: 'Inter' }}
          text={title}
        />
        <Space type="medium" />
        <Text type="h7.400" sx={{ color: styles.tutorialBoxText }} text={text} />
      </Box>
      <Space type="xlarge" />
    </Box>
  );
};

export default HomeTutorialBox;
